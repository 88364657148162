<template>
  <div class="cristais-etericos">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container">
      <br />
      <img
        src="@/assets/conselho_carmico.jpg"
        class="img-fluid mx-auto d-block"
        width="320"
        alt="Conselho Cármico"
      />
      <br />
      <hr />
      <h4 class="cor-violeta">
        Receba nosso Ebook gratuitamente Cadastrando-se Aqui:
      </h4>
      <form>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Endereço de Email</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="exemplo@email.com"
            v-model="emailDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retEmail }}</div>
        </div>
        <div class="mb-3">
          <label for="exampleInputNome1" class="form-label"
            >Nome Completo</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputNome1"
            placeholder="Digite seu nome"
            v-model="nomeDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retNome }}</div>
        </div>
        <br />
        <div class="botao">
          <a class="btn-comprar" v-on:click="cadastrar()">Cadastrar</a>
        </div>
        <br />
      </form>
      <hr />
      <br />
      <Title2
        text="O que é Ritual do Conselho Cármico?"
        titleStyle="color: #7e1dce"
      />
      <br />
      <Paragraph
        text="Primeiramente, é essencial que você saiba exatamente o que é Ritual do Conselho
              Cármico para entender melhor como ele funciona."
      />
      <Paragraph
        text="O Ritual do Conselho Cármico é, em resumo, uma espécie de reunião dos seres
              iluminados, os Senhores do carma, onde eles tratam de assuntos interplanetários.
              Esses Seres de Luz são responsáveis pelo equilíbrio cármico da humanidade. E você
              pode “participar” desse ritual realizando algumas ações."
      />
      <Paragraph
        text="Então, esses seres reúnem-se por quatro vezes ao ano. Nos últimos dias de março,
              junho, setembro e dezembro. Nessas datas, o Conselho Cármico libera a Luz da
              Misericórdia de Deus, Violeta Púrpura que leva aos seres humanos o auxílio e a redução
              dos fardos cármicos."
      />
      <Paragraph
        text="Este e-book irá auxiliá-la (o) a se comunicar com o Universo e os Mestres do Conselho
              Cármico trazendo uma maior compreensão de seus desejos e auxiliar a amenizar os
              efeitos do carma, pois a verdade é essencial para entender o fluxo da vida e conviver em
              equilíbrio e harmonia."
      />
      <br />
      <Title
        class="cor-violeta"
        text="Seu Eu Sou abrirá novas portas para a percepção de sua realidade e trará mais abundância e prosperidade para sua vida!"
      />
      <hr />
      <IdentBottom />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import IdentBottom from '@/components/IdentBottom.vue';
import Title2 from '@/components/Title2.vue';
import Title from '@/components/Title.vue';

export default {
  name: 'ConselhoCarmico',
  components: {
    NavBar,
    Paragraph,
    IdentBottom,
    Title2,
    Title,
  },
  props: {
    emailDigitado: String,
    nomeDigitado: String,
    retEmail: String,
    retNome: String,
  },
  mounted: function () {
    this.retEmail = ' ';
    this.retNome = ' ';
  },
  methods: {
    emailIsValid: function (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    cadastrar: function () {
      // Faz uma chamada para a rota Que verifica se o email está ativo pelo token
      const email = this.emailDigitado;
      const name = this.nomeDigitado;
      this.retEmail = ' ';
      this.retNome = ' ';
      if (!email || email == '' || !this.emailIsValid(email)) {
        this.retEmail = 'Digite um email válido';
        return;
      }
      if (!name || name == '' || name.length < 3) {
        this.retNome = 'Digite seu nome';
        return;
      }
      const id_campaign = 'a0b87e65-d019-402c-a5e7-51c841bbf2d1';
      axios
        .post(
          'https://www.escolaalquimiaquantica.com.br/api/emails',
          {
            name,
            email,
            id_campaign,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET, POST, PATCH, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers':
                'Origin, Content-Type, X-Auth-Token',
            },
          },
        )
        .then(resp => {
          const link = resp.data['link'];
          if (resp.status == 200) {
            if (link !== '') {
              this.$router.push(link);
            }
            return;
          }
          if (resp.status != 200) {
            alert('Não foi possível Cadastrar o Email. Tente novamente. ');
          }
        })
        .catch(() =>
          alert('Não foi possível Cadastrar o Email. Tente novamente. '),
        );
    },
  },
};
</script>

<style scoped>
.formulario {
  border: 1px solid rebeccapurple;
  box-shadow: rgba(194, 154, 231, 0.445);
}
.cor-violeta {
  color: #7e1dce;
}
.botao-comprar {
  text-align: center;
  margin-block: 40px;
}

.btn-comprar {
  padding: 20px 40px;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: #f5eef8;
  background: #aa00ff;
  border-radius: 30px;
  box-shadow: 3px 4px 3px #7a12c9;
  transition: 300ms;
}

a:link {
  color: #f5eef8;
}
a:visited {
  color: #f5eef8;
}
a:hover {
  color: #f5eef8;
  background: #e254ff;
  box-shadow: 6px 7px 7px #7a12c9;
  text-shadow: 1px 1px 2px #290742;
  cursor: default;
}
a:active {
  color: #f5eef8;
  background: #8913e2;
  box-shadow: 3px 3px 5px #7a12c9;
}
.vermelho {
  color: rgb(148, 16, 16);
}
</style>
