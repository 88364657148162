<template>
  <div class="gerador-azul">
    <NavBar navStyle="background-color: #4b31bd" />
    <div class="container fundo">
      <Title text="Gerador de Força Radiônica de Arcanjo Miguel" />
      <Title2 text="O que é?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="O Gerador de Força Radiônica é um motor Gerador/Captador de Forças Cósmicas Universais, que através da disposição dos
            cristais, da força mental e da canalização de energias Etéricas é ativado e programado para gerar e transmitir esses
            raios para determinadas situações e pessoas, realizando a limpeza profunda de energias densas e a manutenção das
            energias em nosso campo áurico."
      />
      <Title2 text="Como funciona a Terapia?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="É um Sistema de Cura Cósmica, que é ativado à distância, com a utilização e ativação dos símbolos da Alquimia Quântica da Chama Azul, cristais físicos, energias Etéricas Universais, canalizadas pelo Alquimista iniciado, com o ancoramento da Hierarquia Espiritual do 1º Raio Azul, de Arcanjo Miguel e Mestre El Morya, que atuam diretamente nos planos onde se manifestam nossos corpos."
      />
      <Title2 text="Como é o Tratamento?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="É realizada a consulta presencial ou à distância, para o recebimento das orientações a seguir e solicitação de alguns
            dados pessoais."
      />
      <Paragraph
        text="Ativado o Gerador para tratamento por 1 hora, à distância, com altas frequências energéticas e só pode ser realizado a cada vinte e um dias para a mesma pessoa. "
      />
      <Paragraph
        text="São realizados Mantras e Decretos específicos e comandos quânticos durante o processo de tratamento e a disposição dos cristais ativados."
      />
      <Title2 text="Para que Serve?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="Tratamento fortíssimo que retira todos os bloqueios trazidos desde seus Registros Akáshicos, isto é, de suas outras
            vidas e experiências dos corpos físico, mental, emocional e espiritual, trazidas através do sistema familiar,
            registrados no DNA, fazendo uma varredura destas energias em todos os níveis e dimensões de manifestação e em todas as
            linhas de tempo e espaço. Influenciando nos pensamentos, ações, hábitos e sentimentos diários e limpeza espiritual do
            campo energético, corte de laços negativos, transmutação de miasmas, mucos, vírus astrais, desobsessão."
      />
      <br />
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'GeradorAzul',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
