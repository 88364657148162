<template>
  <div class="home">
    <NavBar />
    <div class="container fundo">
      <br />
      <br />
      <Title text="Neste Novo Milênio, queremos trazer Luz à sua vida!" />
      <Paragraph
        text="A Escola Alquimia Quântica tem como Filosofia o uso consciente da Presença “Eu Sou” e o reconhecimento e plena aceitação
            de Deus em Ação, a Infinitude da Fonte e Centro."
      />
      <Paragraph
        text="Com a Finalidade de auxiliar os seres humanos a aprender como funciona o processo de alcançar a mente divina, para que
            examinem sua realidade, seu ser holisticamente e o que desejam co-criar para seu mundo, curando a si mesmos e aos
            outros, percebendo a pulsação da sua verdade que já está fluindo, manifestando abundância e prosperidade em todos os
            níveis de seu Ser."
      />
      <Paragraph
        text="Utilizando conhecimentos de Sistemas de Curas Milenares, a Aplicação da Alquimia Quântica da Chama Violeta e da Chama
            Azul, Cristais e Florais Etéricos e outras técnicas de Terapias Holísticas e a disponibilização de Cursos de Formação,
            libertando as consciências para ascender em Luz, visando à melhoria da qualidade de vida e o equilíbrio da saúde dos
            corpos em todos os níveis de manifestação."
      />
      <Title text="Pela cura do Planeta Terra Urgente!" />
      <hr />
      <HRImage />
      <img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=299736969009449&ev=ViewContent"
      />
    </div>
    <br />
    <br />
    <div class="container">
      <div class="container px-8">
      <img
        src="@/assets/cartas_de_cristo.jpeg"
        class="img-fluid mx-auto d-block"
        width="320"
        alt="Cartas de Cristo"
        />
        <br />
        <br />
        <Paragraph
          text="Qual a origem das cartas de Cristo? As mensagens das CARTAS DE CRISTO foram psicografadas por uma mulher de 80 anos,
            inglesa que foi educada em um convento católico, dos quais 40 anos de sua vida foi purificada para receber essa mensagem,
            sob a direção de Cristo."
        />
        <br />
        <div class="imagem">
          <img
            src="@/assets/cartas_de_cristo1.jpeg"
            class="img-fluid mx-auto d-block"
            alt="As Cartas de Cristo"
            width="320"
            height="320"
          />
        </div>
        <br />
        <Title text="Baixar As Cartas de Cristo PDF gratuito" />
        <div class="row gx-5">
          <div class="col">
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta1.pdf"
                > Baixar Carta 1</a
              ></span
            >
          </div>
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta2.pdf"
                >Baixar Carta 2</a
              ></span
            >
          </div>
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta3.pdf"
                >Baixar Carta 3</a
              ></span
            >
          </div>
          </div>
          <div class="col">
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta4.pdf"
                >Baixar Carta 4</a
              ></span
            >
          </div>
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta5.pdf"
                >Baixar Carta 5</a
              ></span
            >
          </div>
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta6.pdf"
                >Baixar Carta 6</a
              ></span
            >
          </div>
          </div>
          <div class="col">
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta7.pdf"
                >Baixar Carta 7</a
              ></span
            >
          </div>
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta8.pdf"
                >Baixar Carta 8</a
              ></span
            >
          </div>
          <div class="p3">
            <span
              >
              <a href="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/carta9.pdf"
                >Baixar Carta 9</a
              ></span
            >
          </div>
          </div>
        </div>
    <hr />
    <div class="container">
      <div class="container px-8">
        <Title
          text="Tratamentos com as Terapias Holísticas da Alquimia Quântica da Chama Azul de Arcanjo Miguel e Alquimia Quântica da Chama
            Violeta do Mestre Saint Germain"
        />
        <Paragraph
          text="As energias ancoradas nas ativações da Alquimia Quântica da Chama Azul, do 1º Raio Cósmico e da Alquimia Quântica da
                Chama Violeta, do 7º Raio Cósmico, estabelecem um novo padrão energético quando realizadas as aplicações nos tratamentos
                terapêuticos, nos tornando capazes de acessar frequências elevadíssimas de 5ª dimensão, e a fim de estarmos cada vez
                mais conscientes da nossa evolução e ascendermos na Luz."
        />
        <Paragraph
          text="O Alquimista Quântico é um curador iniciado que possui habilidades capazes de limpar as negatividades, doenças e carmas
                humanos através da aplicação das energias dos Raios Crísticos de Deus, acessando e manipulando as energias através das
                técnicas milenares de cura."
        />
        <Paragraph
          text="As técnicas e energias das Alquimias dos Raios vieram com a proposta de trazer de volta a alta magia dos Templos
                Iniciáticos e das Escolas de Mistérios da Idade de Ouro da Atlântida, proporcionando-nos a conexão direta com as fortes
                e transformadoras energias desta Nova Era, a Era de Aquário."
        />
        <div class="row gx-5">
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="chama_azul.jpg"
                btnLink="/chama_azul"
                text="Chama Azul - Arcanjo Miguel"
                imgAlt="Chama Azul"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="chama_violeta.jpeg"
                btnLink="/chama_violeta"
                text="Chama Violeta - Saint Germain"
                imgAlt="Chama Violeta"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <HRImage2 />
    </div>

    <div class="container">
      <div class="container px-8">
        <Title
          text="Tratamentos com as Terapias com os Florais Etéricos e Físicos e Cristais Etéricos e Físicos"
        />
        <Paragraph
          text="Muitas doenças são causadas por nós mesmos, por situações e conflitos que criamos com nosso comportamento diante da
                vida, nossa boca, nosso pensamento, nossas ações e diante desta verdade, foram canalizadas vários sistemas de cura, por
                mestres, como o Sistema dos Florais Etéricos que, através do qual, é possível fazê-los com um comando mental do iniciado
                no sistema recebendo a autorização do Universo e dos diversos elementos para manusear as energias harmônicas que
                restabelecerão o equilíbrio do corpo, mente e espírito, de acordo com a doença ou necessidade apresentada pelo Ser que o
                utilizará para tratamento."
        />
        <Paragraph
          text="Os Florais e Cristais Etéricos e Físicos são Sistemas que podem auxiliar e muito no tratamento de emoções e situações
                conflituosas, trazendo a harmonia, saúde e bem-estar para você que busca a cura de sua alma!"
        />
        <div class="row gx-5">
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="florais.jpeg"
                btnLink="/florais_etericos"
                text="Florais Etéricos de Arcanjo Miguel"
                imgAlt="Florais Etéricos"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="cristais.jpeg"
                btnLink="/cristais_etericos"
                text="Cristais Etéricos"
                imgAlt="Cristais Etéricos"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
    </div>
    <div class="container">
      <div class="container px-8">
        <Title text="Técnicas e Meditações Gratuitas" />
        <div class="row gx-5">
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="ebook_conselho_carmico.jpeg"
                btnLink="/conselho_carmico"
                text="Ritual ao Conselho Cármico"
                imgAlt="Ritual Conselho Cármico"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="ebook_decretos.jpg"
                btnLink="/decretos"
                text="Decretos do Livro de Ouro de Saint Germain"
                imgAlt="Decretos do Livro de Ouro de Saint Germain"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="bastao.jpeg"
                btnLink="/bastao_atlantes"
                text="Ativação da Técnica do Bastão Atlantes"
                imgAlt="Bastão Atlantes"
              />
            </div>
          </div>
        </div>
        <div class="row gx-5">
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="causas_das_doencas.jpeg"
                btnLink="/causas_das_doencas"
                text="As Causas das Doenças"
                imgAlt="As Causas das Doenças"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="equilibrio_chakras.jpeg"
                btnLink="/equilibrio_chakras"
                text="Equilíbrio dos Chakras"
                imgAlt="Equilíbrio dos Chakras"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="o_despertar.jpeg"
                btnLink="/despertar_consciencia"
                text="O Despertar de uma nova consciência"
                imgAlt="O Despertar de uma nova consciência"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
    </div>
    <div class="container">
      <Journey />
      <hr />
    </div>
    <div class="container">
      <PresentationVideo />
      <br />
    </div>
    <div class="container">
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Card from '@/components/Card.vue';
import Journey from '@/components/Journey.vue';
import PresentationVideo from '@/components/PresentationVideo.vue';
import Contato from '@/components/Contato.vue';
import HRImage from '@/components/HRImage.vue';
import HRImage2 from '@/components/HRImage2.vue';

export default {
  name: 'Home',
  components: {
    NavBar,
    Title,
    Paragraph,
    Card,
    Journey,
    PresentationVideo,
    Contato,
    HRImage,
    HRImage2,
  },
};
</script>
