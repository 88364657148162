<template>
  <div class="verifica-email">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container fundo">
      <br />
      <br />
      <Title text="Obrigado por se Cadastrar!" />
      <br />
      <Title2
        text="Verifique no seu e-mail o link para Baixar seu e-book!"
        titleStyle="color: #7e1dce"
        class="centralizar"
      />
      <br />
      <br />
      <p class="centralizar">
        <b>Caso não encontre</b>, verifique em sua <b>caixa de spam</b> ou
        <b>lixeira</b>.
      </p>
      <br />
      <hr />
      <IdentBottom />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import IdentBottom from '@/components/IdentBottom.vue';

export default {
  name: 'VerificaEmail',
  components: {
    NavBar,
    Title,
    Title2,
    IdentBottom,
  },
};
</script>

<style scoped>
.centralizar {
  text-align: center;
}
</style>
