<template>
  <p class="opensans text-justify recuo paragrafo">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'Paragraph',
  props: {
    text: String,
  },
};
</script>

<style scoped>
.paragrafo {
  line-height: 1.8;
  margin-bottom: 6px;
}
</style>
