<template>
  <div class="florais-etericos">
    <NavBar navStyle="background-color: #31bd54" />
    <div class="container fundo">
      <Title text="O que são os Florais Etéricos?" />
      <Paragraph
        text="Os Florais Etéricos vêm atuar nos planos físico, mental, emocional e espiritual, trazendo de volta o equilíbrio e
            recuperação da saúde, num efeito sistêmico. Além de trabalharem nestes planos, realizam uma limpeza de emoções negativas
            criadas ou herdadas dos antepassados através do Sistema Familiar. Os Florais Etéricos servem para clarear o que precisa
            ser transmutado em nossa vida. As imagens, pensamentos negativos e crenças limitantes armazenados no subconsciente, se
            manifestam na vida do indivíduo, criando, muitas vezes, bloqueios, excessos e desiquilíbrios. Cada ser contém inúmeros
            elementos harmônicos dentro do próprio organismo para restabelecer seu equilíbrio e, com o uso dos florais, o corpo
            começa produzir enzimas capazes de fortalecer o Sistema Nervoso e o Sistema Endócrino que estão ligados aos Corpos
            Sutis, Chakras e Meridianos, realizando limpezas dessas crenças e registros provenientes de outras vidas, nos níveis dos
            Registros Akáshicos, energias negativas e da áura. O Sistema dos Florais Etéricos é um Método que funciona independente
            da Fé, Credo, Filosofia ou Religião."
      />
      <br />
      <Title2
        text="Busque as curas para sua Alma!"
        titleStyle="color: #31bd54"
      />
      <br />
      <br />
      <Botao
        texto="Escolha aqui seus florais"
        url="https://escola-alquimia-quantica.s3.amazonaws.com/ebooks/Sistemas+de+Florais+Et%C3%A9ricos+2021.pdf"
      />
      <br />
    </div>
    <hr />
    <Contato
      text="Agende um atendimento via Whatsapp"
      btnLink="https://api.whatsapp.com/send?phone=5554996414661"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';
import Botao from '@/components/Botao.vue';

export default {
  name: 'FloraisEtericos',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
    Botao,
  },
};
</script>
