<template>
  <div class="title2">
    <h4 class="opensans" :style="titleStyle">
      {{ text }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'Title2',
  props: {
    text: String,
    titleStyle: String,
  },
};
</script>
