<template>
  <div class="cristais-etericos">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container">
      <br />
      <img
        src="@/assets/causas_das_doencas.jpeg"
        class="img-fluid mx-auto d-block"
        width="320"
        alt="As Causas das Doenças"
      />
      <br />
      <hr />
      <h4 class="cor-violeta">
        Receba nosso Ebook gratuitamente Cadastrando-se Aqui:
      </h4>
      <form>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Endereço de Email</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="exemplo@email.com"
            v-model="emailDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retEmail }}</div>
        </div>
        <div class="mb-3">
          <label for="exampleInputNome1" class="form-label"
            >Nome Completo</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputNome1"
            placeholder="Digite seu nome"
            v-model="nomeDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retNome }}</div>
        </div>
        <br />
        <div class="botao">
          <a class="btn-comprar" v-on:click="cadastrar()">Cadastrar</a>
        </div>
        <br />
      </form>
      <hr />
      <br />
      <Title2 text="As Causas das Doenças" titleStyle="color: #7e1dce" />
      <br />
      <Paragraph
        text="Desde que eu iniciei o meu caminho de autoconhecimento e desenvolvimento pessoal, tenho encontrado técnicas, métodos e pessoas maravilhosas que tem me ajudado a expandir a minha consciência e a buscar as curas para minha alma..."
      />
      <Paragraph
        text="Essa é uma das propostas da Escola Alquimia Quântica para você..."
      />
      <Paragraph
        text="Ela é uma escola de ascensão que objetiva auxiliar as pessoas em seus processos de cura com os Tratamentos Terapêuticos e Cursos de Formação!"
      />
      <Paragraph
        text="O que tenho aprendido nas formações como terapeuta holística tem causado grandes grande diferença em minha vida e por isso eu quero compartilhar com você uma leitura que lhe abrirá uma conexão consciente com as causas das doenças e começará a entender o caminho para mudar esse cenário..."
      />
      <Paragraph
        text="Este e-book irá auxiliá-la (o) a conhecer as causas das doenças e de que formas se manifestam essas energias em nossa vida, quais cuidados e considerações devemos ter com nossa própria saúde e bem estar... Uma das causas das doenças é o acúmulo de energia em algumas partes do corpo mais do que o necessário..."
      />
      <br />
      <Title
        class="cor-violeta"
        text="Leia nosso Ebook e alinhe-se com as Energias de Cura..."
      />
      <hr />
      <IdentBottom />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import IdentBottom from '@/components/IdentBottom.vue';
import Title2 from '@/components/Title2.vue';
import Title from '@/components/Title.vue';

export default {
  name: 'CausasDasDoencas',
  components: {
    NavBar,
    Paragraph,
    IdentBottom,
    Title2,
    Title,
  },
  props: {
    emailDigitado: String,
    nomeDigitado: String,
    retEmail: String,
    retNome: String,
  },
  mounted: function () {
    this.retEmail = ' ';
    this.retNome = ' ';
  },
  methods: {
    emailIsValid: function (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    cadastrar: function () {
      // Faz uma chamada para a rota Que verifica se o email está ativo pelo token
      const email = this.emailDigitado;
      const name = this.nomeDigitado;
      this.retEmail = ' ';
      this.retNome = ' ';
      if (!email || email == '' || !this.emailIsValid(email)) {
        this.retEmail = 'Digite um email válido';
        return;
      }
      if (!name || name == '' || name.length < 3) {
        this.retNome = 'Digite seu nome';
        return;
      }
      const id_campaign = '2429d336-1661-4ee3-bfbc-3eb926170aa7';
      axios
        .post(
          'https://www.escolaalquimiaquantica.com.br/api/emails',
          {
            name,
            email,
            id_campaign,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET, POST, PATCH, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers':
                'Origin, Content-Type, X-Auth-Token',
            },
          },
        )
        .then(resp => {
          const link = resp.data['link'];
          if (resp.status == 200) {
            if (link !== '') {
              this.$router.push(link);
            }
            return;
          }
          if (resp.status != 200) {
            alert('Não foi possível Cadastrar o Email. Tente novamente. ');
          }
        })
        .catch(() =>
          alert('Não foi possível Cadastrar o Email. Tente novamente. '),
        );
    },
  },
};
</script>

<style scoped>
.formulario {
  border: 1px solid rebeccapurple;
  box-shadow: rgba(194, 154, 231, 0.445);
}
.cor-violeta {
  color: #7e1dce;
}
.botao-comprar {
  text-align: center;
  margin-block: 40px;
}

.btn-comprar {
  padding: 20px 40px;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: #f5eef8;
  background: #aa00ff;
  border-radius: 30px;
  box-shadow: 3px 4px 3px #7a12c9;
  transition: 300ms;
}

a:link {
  color: #f5eef8;
}
a:visited {
  color: #f5eef8;
}
a:hover {
  color: #f5eef8;
  background: #e254ff;
  box-shadow: 6px 7px 7px #7a12c9;
  text-shadow: 1px 1px 2px #290742;
  cursor: default;
}
a:active {
  color: #f5eef8;
  background: #8913e2;
  box-shadow: 3px 3px 5px #7a12c9;
}
.vermelho {
  color: rgb(148, 16, 16);
}
</style>
