<template>
  <div class="download-ebook">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container fundo">
      <br />
      <img
        src="@/assets/causas_das_doencas.jpeg"
        class="img-fluid mx-auto d-block"
        width="320"
        alt="As Causas das Doenças"
      />
      <br />
      <Title
        class="cor-violeta"
        text="Clique no botão abaixo para Baixar seu e-book!"
      />
    </div>
    <div class="container">
      <div class="col formulario">
        <div class="botao">
          <a class="btn-comprar" v-on:click="download()"
            >Baixar e-book As Causas das Doenças</a
          >
        </div>
        <br />
      </div>
      <hr />
      <IdentBottom />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Title from '@/components/Title.vue';
import NavBar from '@/components/NavBar.vue';
import IdentBottom from '@/components/IdentBottom.vue';
import axios from 'axios';

export default {
  name: 'DownloadEbookCausasDasDoencas',
  components: {
    Title,
    NavBar,
    IdentBottom,
  },
  props: {
    link: String,
    token: String,
  },
  mounted: function () {
    this.$nextTick(function () {
      this.token = this.$route.query['token'];
      if (this.token != undefined && this.token !== '') {
        axios
          .post(
            'https://www.escolaalquimiaquantica.com.br/api/emails/activate',
            {
              hash: this.token,
              active: 1,
            },
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods':
                  'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                'Access-Control-Allow-Headers':
                  'Origin, Content-Type, X-Auth-Token',
              },
            },
          )
          .then()
          .catch(() => alert('Não foi possível ativar o Email.'));
      } else {
        this.$router.push('causas_das_doencas');
      }
    });
  },
  methods: {
    download: function () {
      // Faz uma chamada para a rota Que verifica se o email está ativo pelo token
      if (this.token && this.token !== '') {
        axios
          .post(
            'https://www.escolaalquimiaquantica.com.br/api/emails/isactive',
            {
              hash: this.token,
            },
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods':
                  'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                'Access-Control-Allow-Headers':
                  'Origin, Content-Type, X-Auth-Token',
              },
            },
          )
          .then(response => {
            axios
              .get(
                'https://www.escolaalquimiaquantica.com.br/api/campaigns/' +
                  response.data['id_campaign'],
              )
              .then(
                resp => {
                  const linkEbook = resp.data['link'];
                  window.location.href = linkEbook;
                },
                {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods':
                      'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers':
                      'Origin, Content-Type, X-Auth-Token',
                  },
                },
              )
              .catch(() => alert('Erro ao buscar Link do Ebook.'));
          })
          .catch(() => alert('Erro ao verificar Email.'));
      }
    },
  },
};
</script>

<style scoped>
.formulario {
  box-shadow: rgba(194, 154, 231, 0.445);
}
.cor-violeta {
  color: #7e1dce;
}
.botao-comprar {
  text-align: center;
  margin-block: 40px;
}

.botao {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn-comprar {
  padding: 20px 40px;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: #f5eef8;
  background: #aa00ff;
  border-radius: 30px;
  box-shadow: 3px 4px 3px #7a12c9;
  transition: 300ms;
}

a:link {
  color: #f5eef8;
}
a:visited {
  color: #f5eef8;
}
a:hover {
  color: #f5eef8;
  background: #e254ff;
  box-shadow: 6px 7px 7px #7a12c9;
  text-shadow: 1px 1px 2px #290742;
  cursor: default;
}
a:active {
  color: #f5eef8;
  background: #8913e2;
  box-shadow: 3px 3px 5px #7a12c9;
}
</style>
