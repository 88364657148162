<template>
  <img src="../assets/faixa03.png" width="100%" alt="" class="imgFaixa" />
</template>

<script>
export default {
  name: 'HRImage2',
};
</script>

<style scoped>
.imgfaixa {
  width: 200px;
}
</style>
