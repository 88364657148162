<template>
  <div class="container">
    <img
      src="@/assets/anjo_chakras.jpeg"
      class="img-fluid mx-auto d-block imagem"
      width="400"
      alt="Escola Alquimia Quantica - Terapias"
    />
    <br />
    <Title
      text="Produtos e serviços oferecidos nesta Assinatura Semestral Terapêutica:"
    />
    <div class="justify-content-center align-items-center">
      <div class="align-self-center">
        <ul class="list-unstyled opensans">
          <li>
            <ul>
              <li>
                1º Mês: Ativação do Reiki Alquímico da Chama Violeta – R$144,00
              </li>
              <li>
                2º Mês: Ativação do Gerador de Força Radiônica de Arcanjo Miguel
                - R$144,00
              </li>
              <li>
                3º Mês: Tratamento com Florais Físicos escolhidos de acordo com
                sua verdade e desejo personalizado e enviado após sua escolha –
                R$ 27,00
              </li>
              <li>
                4º Mês: Ativação do Gerador de Força Radiônica da Chama Violeta
                –R$ 144,00
              </li>
              <li>
                5º Mês: Tratamento com o Kit 7 chakras de Cristais Físicos
                potencializados com os cristais etéricos em cada um, enviados
                junto as orientações de uso para limpeza, equilíbrio e
                energização dos chakras – R$27,00
              </li>
              <li>
                6º Mês: Limpeza energética para sua residência com os Símbolos
                da Alquimia Quântica da Chama Azul - R$ 179,00 + Ativação do
                Reiki Alquímico da Chama Azul - 144,00
              </li>
              <li>+ 2 E-books como BÔNUS – R$ 57,00</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <h4 class="frase-center opensans">
      Estes Tratamentos para sua beleza interior te farão se sentir uma mulher
      da Nova Era!
    </h4>
  </div>
</template>

<script>
import Title from '@/components/Title.vue';

export default {
  name: 'ProdutosAssinatura',
  components: {
    Title,
  },
};
</script>

<style scoped>
.imagem {
  border-radius: 20px;
  box-shadow: 2px 2px 4px rgb(119, 90, 138);
}

.frase-center {
  color: rgb(126, 40, 206);
  text-align: center;
  line-height: 1.8;
  margin-top: 18px;
  margin-bottom: 6px;
}
</style>
