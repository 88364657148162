<template>
  <div class="cristais-etericos">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container">
      <br />
      <img
        src="@/assets/o_despertar.jpeg"
        class="img-fluid mx-auto d-block"
        width="320"
        alt="O Despertar de uma Nova Consciência"
      />
      <br />
      <hr />
      <h4 class="cor-violeta">
        Receba nosso Ebook gratuitamente Cadastrando-se Aqui:
      </h4>
      <form>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Endereço de Email</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="exemplo@email.com"
            v-model="emailDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retEmail }}</div>
        </div>
        <div class="mb-3">
          <label for="exampleInputNome1" class="form-label"
            >Nome Completo</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputNome1"
            placeholder="Digite seu nome"
            v-model="nomeDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retNome }}</div>
        </div>
        <br />
        <div class="botao">
          <a class="btn-comprar" v-on:click="cadastrar()">Cadastrar</a>
        </div>
        <br />
      </form>
      <hr />
      <br />
      <Title2
        text="O despertar de uma nova consciência - Eckhart Tolle"
        titleStyle="color: #7e1dce"
      />
      <br />
      <Paragraph
        text="Mais do que em qualquer outra época de sua história, a humanidade tem hoje a chance de criar um mundo novo – mais evoluído espiritualmente, mais pleno de amor e sanidade."
      />
      <Paragraph
        text="Para Eckhart Tolle, estamos vivendo um momento único e maravilhoso: o do despertar de uma nova consciência. Ele nos mostra que o salto para essa nova realidade depende de uma mudança interna radical em cada um de nós."
      />
      <Paragraph
        text="Precisamos nos livrar do controle do ego, pois essa é a fonte de todo o sofrimento humano. Sob seu domínio, somos incapazes de ver a dor que infligimos a nós mesmos e aos outros. Quando despertamos, o pensamento perde a ascendência sobre nós e se torna o servo da consciência, que é a ligação com a inteligência universal, a fonte da vida da qual todos nós procedemos."
      />
      <Paragraph
        text="Enquanto desvenda a natureza dessa mudança de consciência, Tolle nos ensina a vencer as artimanhas que o ego utiliza para nos isolar uns dos outros. De forma inspiradora, ele nos ajuda a descobrir o nosso verdadeiro eu, a essência humana que nos permitirá construir o novo mundo e viver em harmonia com tudo o que existe."
      />
      <Paragraph
        text="“O principal propósito deste livro não é acrescentar novas informações e crenças à sua mente nem tentar convencê-lo de alguma coisa, e sim produzir uma modificação da sua consciência, ou seja, o despertar. Se você não sabe o que significa despertar, continue lendo.” – Eckhart Tolle"
      />
      <br />
      <Title class="cor-violeta" text="Baixe o Livro em PDF e Boa Leitura!" />
      <hr />
      <IdentBottom />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import IdentBottom from '@/components/IdentBottom.vue';
import Title2 from '@/components/Title2.vue';
import Title from '@/components/Title.vue';

export default {
  name: 'DespertarConsciencia',
  components: {
    NavBar,
    Paragraph,
    IdentBottom,
    Title2,
    Title,
  },
  props: {
    emailDigitado: String,
    nomeDigitado: String,
    retEmail: String,
    retNome: String,
  },
  mounted: function () {
    this.retEmail = ' ';
    this.retNome = ' ';
  },
  methods: {
    emailIsValid: function (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    cadastrar: function () {
      // Faz uma chamada para a rota Que verifica se o email está ativo pelo token
      const email = this.emailDigitado;
      const name = this.nomeDigitado;
      this.retEmail = ' ';
      this.retNome = ' ';
      if (!email || email == '' || !this.emailIsValid(email)) {
        this.retEmail = 'Digite um email válido';
        return;
      }
      if (!name || name == '' || name.length < 3) {
        this.retNome = 'Digite seu nome';
        return;
      }
      const id_campaign = '6a1302b9-9669-431a-ad06-10f6c646b5ae';
      axios
        .post(
          'https://www.escolaalquimiaquantica.com.br/api/emails',
          {
            name,
            email,
            id_campaign,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET, POST, PATCH, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers':
                'Origin, Content-Type, X-Auth-Token',
            },
          },
        )
        .then(resp => {
          const link = resp.data['link'];
          if (resp.status == 200) {
            if (link !== '') {
              this.$router.push(link);
            }
            return;
          }
          if (resp.status != 200) {
            alert('Não foi possível Cadastrar o Email. Tente novamente. ');
          }
        })
        .catch(() =>
          alert('Não foi possível Cadastrar o Email. Tente novamente. '),
        );
    },
  },
};
</script>

<style scoped>
.formulario {
  border: 1px solid rebeccapurple;
  box-shadow: rgba(194, 154, 231, 0.445);
}
.cor-violeta {
  color: #7e1dce;
}
.botao-comprar {
  text-align: center;
  margin-block: 40px;
}

.btn-comprar {
  padding: 20px 40px;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: #f5eef8;
  background: #aa00ff;
  border-radius: 30px;
  box-shadow: 3px 4px 3px #7a12c9;
  transition: 300ms;
}

a:link {
  color: #f5eef8;
}
a:visited {
  color: #f5eef8;
}
a:hover {
  color: #f5eef8;
  background: #e254ff;
  box-shadow: 6px 7px 7px #7a12c9;
  text-shadow: 1px 1px 2px #290742;
  cursor: default;
}
a:active {
  color: #f5eef8;
  background: #8913e2;
  box-shadow: 3px 3px 5px #7a12c9;
}
.vermelho {
  color: rgb(148, 16, 16);
}
</style>
