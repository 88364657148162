<template>
  <div class="cristais-etericos">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container">
      <br />
      <img
        src="@/assets/ebook_decretos.jpg"
        class="img-fluid mx-auto d-block"
        width="320"
        alt="Decretos Livro de Ouro de Saint Germain"
      />
      <br />
      <hr />
      <h4 class="cor-violeta">
        Receba nosso Ebook gratuitamente Cadastrando-se Aqui:
      </h4>
      <form>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Endereço de Email</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="exemplo@email.com"
            v-model="emailDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retEmail }}</div>
        </div>
        <div class="mb-3">
          <label for="exampleInputNome1" class="form-label"
            >Nome Completo</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputNome1"
            placeholder="Digite seu nome"
            v-model="nomeDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retNome }}</div>
        </div>
        <br />
        <div class="botao">
          <a class="btn-comprar" v-on:click="cadastrar()">Cadastrar</a>
        </div>
        <br />
      </form>

      <hr />
      <br />
      <Title2
        text="Baixe Agora Gratuitamente o Ebook das Afirmações do Eu Sou!"
        titleStyle="color: #7e1dce"
      />
      <br />
      <Paragraph
        text="Desde meus 15 anos tive o prazer de conhecer o “O Livro Alquímico de Saint Germain”, que depois de alguns anos minha amiga me deu de presente..."
      />
      <Paragraph
        text="Posso dizer que no primeiro momento já tive muito interesse no conteúdo, mas quando eu abri comecei a leitura e confesso que muito não entendia... E com o passar dos anos muitas experiências, conhecimento e estudo, comecei a compreender o uso do Eu Sou... "
      />
      <Paragraph
        text="Mestre Saint Germain sempre esteve presente em minha vida e após muito tempo realizei a formação de Alquimista Quântica da Chama Violeta e hoje trabalho com estas terapias holísticas..."
      />
      <Paragraph
        text="O prefácio do próprio livro diz que: “O ‘acaso’ não existe; nós quando já estamos preparados para tais situações ou conhecimento, os atraímos em nossas vidas”."
      />
      <Paragraph
        text="E desde então com esse conhecimento da Divina Presença EU SOU, venho estudando e aplicando o conteúdo desse livro quando posso, obtendo resultados valorosos para minha expansão de consciência e uma comunhão verdadeira com minha alma, a Centelha Divina que é o Deus dentro de mim e em meu coração."
      />
      <Paragraph
        text="Saint Germain nos ensina que quando pensamos na expressão “EU SOU”, significa que temos Deus em ação expressado em nossa existência, e que ELE é fartura, saúde, amor, felicidade e perfeição, e que não podemos permitir que falsas avaliações e expressões governem nossa vida, nosso mundo. "
      />
      <Paragraph
        text="Por isso deixo essas afirmações no nosso e-book: “Decretos do Livro de Ouro de Saint Germain para a Era de Aquário”, e convido a fazer uma experiência, escolhendo um decreto por dia e decretando por 9x, sintonizando seu coração na Presença “EU SOU” para que consigas transmutar todos os obstáculos em amor, harmonia, prosperidade, saúde equilíbrio e luz."
      />
      <br />
      <hr />
      <br />
      <Title
        class="cor-violeta"
        text="Seu Eu Sou abrirá novas portas para a percepção de sua realidade e trará mais abundância e prosperidade para sua vida!"
      />
      <hr />
      <IdentBottom />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import NavBar from '@/components/NavBar.vue';
import IdentBottom from '@/components/IdentBottom.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title.vue';
import Paragraph from '@/components/Paragraph.vue';

export default {
  name: 'Decretos',
  components: {
    NavBar,
    IdentBottom,
    Title,
    Title2,
    Paragraph,
  },
  props: {
    emailDigitado: String,
    nomeDigitado: String,
    retEmail: String,
    retNome: String,
  },
  mounted: function () {
    this.retEmail = ' ';
    this.retNome = ' ';
  },
  methods: {
    emailIsValid: function (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    cadastrar: function () {
      // Faz uma chamada para a rota Que verifica se o email está ativo pelo token
      const email = this.emailDigitado;
      const name = this.nomeDigitado;
      this.retEmail = ' ';
      this.retNome = ' ';
      if (!email || email == '' || !this.emailIsValid(email)) {
        this.retEmail = 'Digite um email válido';
        return;
      }
      if (!name || name == '' || name.length < 3) {
        this.retNome = 'Digite seu nome';
        return;
      }
      const id_campaign = '8f4a613d-3a64-43f3-ba44-b915892bc007';
      axios
        .post(
          'https://www.escolaalquimiaquantica.com.br/api/emails',
          {
            name,
            email,
            id_campaign,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET, POST, PATCH, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers':
                'Origin, Content-Type, X-Auth-Token',
            },
          },
        )
        .then(resp => {
          const link = resp.data['link'];
          if (resp.status == 200) {
            if (link !== '') {
              this.$router.push(link);
            }
            return;
          }
          if (resp.status != 200) {
            alert('Não foi possível Cadastrar o Email. Tente novamente. ');
          }
        })
        .catch(() =>
          alert('Não foi possível Cadastrar o Email. Tente novamente. '),
        );
    },
  },
};
</script>

<style scoped>
.formulario {
  border: 1px solid rebeccapurple;
  box-shadow: rgba(194, 154, 231, 0.445);
}
.cor-violeta {
  color: #7e1dce;
}
.botao-comprar {
  text-align: center;
  margin-block: 40px;
}

.btn-comprar {
  padding: 20px 40px;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: #f5eef8;
  background: #aa00ff;
  border-radius: 30px;
  box-shadow: 3px 4px 3px #7a12c9;
  transition: 300ms;
}

a:link {
  color: #f5eef8;
}
a:visited {
  color: #f5eef8;
}
a:hover {
  color: #f5eef8;
  background: #e254ff;
  box-shadow: 6px 7px 7px #7a12c9;
  text-shadow: 1px 1px 2px #290742;
  cursor: default;
}
a:active {
  color: #f5eef8;
  background: #8913e2;
  box-shadow: 3px 3px 5px #7a12c9;
}
.vermelho {
  color: rgb(148, 16, 16);
}
</style>
