<template>
  <div class="reiki-azul">
    <NavBar navStyle="background-color: #4b31bd" />
    <div class="container fundo">
      <Title text="Ativação do Reiki Alquímico da Chama Azul" />
      <Title2 text="O que é?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="A Alquimia Quântica da Chama Azul é um Sistema de Cura Avançada muito profunda, de limpeza espiritual do campo
            energético, corte de laços negativos, transmutação de miasmas, mucos, vírus astrais, desobsessão."
      />
      <Title2 text="Como funciona a Terapia?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="É um Sistema de Cura Cósmica, através da canalização das Energias Etéricas Universais. O Corte de laços negativos e o
            Reiki Alquímico são realizados através da imposição de mãos, que é aplicado presencial ou à distância, com a ativação
            dos símbolos da Alquimia Quântica da Chama Azul e o uso dos cristais físicos pelo Alquimista iniciado, com o ancoramento
            da Hierarquia Espiritual do 1º Raio Azul, de Arcanjo Miguel e Mestre El Morya."
      />
      <Title2 text="Como é o Tratamento?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="É realizada a consulta e atendimento, presencial ou à distância, para o recebimento das orientações a serem seguidas e a
            realização de um Questionário de Análise Terapêutica.
            O paciente se sintoniza com as energias, em horário agendado, em ambiente tranquilo e recebe em torno de 45 minutos de
            tratamento intenso, com a ativação do Laboratório Alquímico e dos quatros elementos."
      />
      <Title2 text="Para que Serve?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="Ativa, amplifica e expande a capacidade de utilização do Poder, da Força, Fé, Vontade Divina, Coragem, Disciplina e
            Determinação para a concretização de metas, para fins individuais e coletivos."
      />
      <Paragraph
        text="Alinha os corpos etéricos, mental e espiritual, promovendo a conexão entre o plano físico e o espiritual, aumentando
            assim a riqueza espiritual, se reflete em um maior equilíbrio no direcionamento da vida na materialidade."
      />
      <Paragraph
        text="Auxilia a retirar as máscaras da personalidade, dissolvendo velhos padrões de repressão, aflorando a expressão verbal de
            pensamentos e sentimentos, combatendo o estresse mental e emocional."
      />
      <Paragraph
        text="Desperta a consciência para assumir as responsabilidades em resolver os problemas, tendo a compreensão inteligente em
            aplicar na sua própria vida para solucionar aqueles problemas que vem se arrastando e sendo deixados pra depois, criando
            um ciclo vicioso. Dessa maneira, adquirindo força, coragem e confiança necessárias para prosseguir, galgando degrau a
            degrau, conseguindo o seu próprio domínio consciente sobre o Eu exterior, o mundo interior, seus pensamentos e
            consequentemente suas ações, se tornando mais disciplinado e ampliando o poder de sua Fé, através de pequenos e
            constantes movimentos e conquistas diárias, nos reconectando ao nosso Eu divino."
      />
      <br />
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'ReikiAzul',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
