<template>
  <div class="simbolos-violeta">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container fundo">
      <Title
        text="Ativação dos Símbolos da Alquimia Quântica da Chama Violeta"
      />
      <h4 class="text-center opensans">
        “EU SOU A PORTA ABERTA QUE NENHUM HOMEM PODE FECHAR!”
      </h4>
      <hr />
      <Title2 text="Ativação da Cruz de Malta" titleStyle="color: #7e1dce" />
      <Paragraph
        text="A Cruz de Malta é usada para ampliar e manifestar novas irradiações ao seu redor, seu corpo, seu lar, seu trabalho e para onde for emitida, carrega o estigma da Chama Violeta, a Cruz de Malta é o formato dos elétrons do Mestre Saint Germain, para o local ou situação que for emitido, este símbolo emana o Próprio Poder do Mestre Saint Germain de transmutar, acelerar a cura e manifestar a Perfeição Divina."
      />
      <Paragraph
        text="É o símbolo da Liberdade de todos os limites e das manifestações de nossos ideais na Luz."
      />
      <Paragraph
        text="Sintonia entre a Terra e o Céu, homem de braços abertos, conecta-se aos quatro elementos do Planeta Terra, Terra, Fogo, água e Ar, e mais o quinto elemento, que permeia tudo o que existe no Cosmo e é a própria Energia da Criação. Símbolo de Proteção, de envios constantes de raios violetas."
      />
      <hr />
      <Title2
        text="Ativação da Espiral da Chama Violeta"
        titleStyle="color: #7e1dce"
      />
      <Paragraph
        text="Está associada à Lua, à água, ao feminino, à evolução cíclica, à vida e à fertilidade, e está representada em muitas divindades femininas do paleolítico, a polaridade da Era de Aquário, que traz o equilíbrio a harmonia e amorosidade da alma feminina. O movimento da espiral é também o movimento da energia dos chacras conforme são descritos nas filosofias hindus."
      />
      <Paragraph
        text="Apesar de indicar um movimento constante, a espiral traduz ao mesmo tempo equilíbrio e ordem inseridos numa permanente mudança."
      />
      <Paragraph
        text="A espiral é um símbolo de evolução e de movimento ascendente e progressivo, positivo, auspicioso e construtivo. Pode ser associada ao movimento de evolução. A forma da espiral é encontrada em todas as culturas e traduz um movimento ascendente de evolução, o que pode até ser associado com a própria progressão da existência. Assim como a vida, a espiral projeta-se para o infinito e aparentemente não tem fim. "
      />
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'SimbolosVioleta',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
