<template>
  <div class="chama-azul">
    <NavBar navStyle="background-color: #4b31bd" />
    <div class="container fundo">
      <Title
        text="Aplicações da Alquimia Quântica da Chama Azul - Arcanjo Miguel"
      />
      <img
        src="@/assets/arcanjo_miguel.jpeg"
        class="img-fluid mx-auto d-block"
        width="220"
        alt="Arcanjo Miguel"
      />
      <br />
      <Title2 text="O que é?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="A poderosa energia da Alquimia Quântica da Chama Azul é de grande
        auxílio na abertura das linhas de comunicação entre as pessoas e
        situações, atuando como desbloqueadora dos caminhos, limpando os
        obstáculos, ajudando em situações de conflito e desentendimento,
        afastando a negatividade que baixa as frequências de energias que
        favorecem todo o tipo de conflitos."
      />
      <Title2 text="Para que serve?" titleStyle="color: #4b31bd" />
      <Paragraph
        text="A Chama Azul tem uma atividade muito intensa e poderosa. Dissolve as
        barreiras emocionais, elimina o embaraço daqueles que se sentem
        socialmente depreciados, estimula o aumento da expressão pessoal e
        reflete em um maior equilíbrio no direcionamento da nossa vida na
        materialidade."
      />
      <Paragraph
        text="Propicia uma libertação Kármica profunda, através de cortes de laços
        negativos do passado que se refletem na realidade atual, dos Registros
        Akáshicos, assim como uma limpeza nos aspectos nocivos do ego, liberta
        de energias intrusas e internas, limpando e trazendo a libertação e
        desbloqueio de doenças, vícios ou sentimentos nocivos e, ativando a
        capacidade de autoproteção e imunização física e energética."
      />
    </div>
    <hr />
    <br />
    <div class="container">
      <div class="container px-8">
        <Title
          text="Tratamentos com as Terapias Holísticas da Alquimia Quântica da Chama
          Azul de Arcanjo Miguel"
        />
        <div class="row gx-5">
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="reiki_azul.jpeg"
                btnLink="/reiki_azul"
                text="Ativação do Reiki Alquímico da Chama Azul"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="gerador_azul.jpeg"
                btnLink="/gerador_azul"
                text="Gerador de Força Radiônica de Arcanjo Miguel"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="simbolos_azul.jpeg"
                btnLink="/simbolos_azul"
                text="Ativações dos Símbolos da Alquimia Quântica da Chama Azul"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Card from '@/components/Card.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'ChamaAzul',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Card,
    Contato,
  },
};
</script>
