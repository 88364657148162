<template>
  <div class="cristais-etericos">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container">
      <br />
      <img
        src="@/assets/equilibrio_chakras.jpeg"
        class="img-fluid mx-auto d-block"
        width="320"
        alt="Equilibrio dos Chakras"
      />
      <br />
      <hr />
      <h4 class="cor-violeta">
        Receba nosso Ebook gratuitamente Cadastrando-se Aqui:
      </h4>
      <form>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Endereço de Email</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="exemplo@email.com"
            v-model="emailDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retEmail }}</div>
        </div>
        <div class="mb-3">
          <label for="exampleInputNome1" class="form-label"
            >Nome Completo</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleInputNome1"
            placeholder="Digite seu nome"
            v-model="nomeDigitado"
          />
          <div id="emailHelp" class="form-text vermelho">{{ retNome }}</div>
        </div>
        <br />
        <div class="botao">
          <a class="btn-comprar" v-on:click="cadastrar()">Cadastrar</a>
        </div>
        <br />
      </form>
      <hr />
      <br />
      <Title2 text="Equilíbrio dos Chakras" titleStyle="color: #7e1dce" />
      <br />
      <Paragraph
        text="Os sete chakras, os centros de energia afetam o cotidiano muito mais do que se pode imaginar. De fato, um problema de saúde específico pode ser causado por um desequilíbrio de um ou mais chakras e, trabalhando com as técnicas apropriadas e terapias holísticas especializadas, muitas doenças podem ser resolvidas."
      />
      <Paragraph
        text="Mas o que são esses chakras, onde eles estão localizados, quais são suas características, o que acontece quando estão em desequilíbrio?"
      />
      <Paragraph
        text="Essas são perguntas que um ser em processo de autoconhecimento provavelmente irá se fazer e é muito importante conhecer as respostas."
      />
      <Paragraph
        text="Neste e-book, você terá uma visão geral do que são os chakras, quais são suas funções e as principais características de cada um."
      />
      <br />
      <Title
        class="cor-violeta"
        text="Baixe gratuitamente nosso e-book e aprenda como se manter em equilíbrio!"
      />
      <hr />
      <IdentBottom />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import IdentBottom from '@/components/IdentBottom.vue';
import Title2 from '@/components/Title2.vue';
import Title from '@/components/Title.vue';

export default {
  name: 'EquilibrioChakras',
  components: {
    NavBar,
    Paragraph,
    IdentBottom,
    Title2,
    Title,
  },
  props: {
    emailDigitado: String,
    nomeDigitado: String,
    retEmail: String,
    retNome: String,
  },
  mounted: function () {
    this.retEmail = ' ';
    this.retNome = ' ';
  },
  methods: {
    emailIsValid: function (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    cadastrar: function () {
      // Faz uma chamada para a rota Que verifica se o email está ativo pelo token
      const email = this.emailDigitado;
      const name = this.nomeDigitado;
      this.retEmail = ' ';
      this.retNome = ' ';
      if (!email || email == '' || !this.emailIsValid(email)) {
        this.retEmail = 'Digite um email válido';
        return;
      }
      if (!name || name == '' || name.length < 3) {
        this.retNome = 'Digite seu nome';
        return;
      }
      const id_campaign = '63d3b44b-5b15-40a8-9317-41d7fd4527f3';
      axios
        .post(
          'https://www.escolaalquimiaquantica.com.br/api/emails',
          {
            name,
            email,
            id_campaign,
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET, POST, PATCH, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers':
                'Origin, Content-Type, X-Auth-Token',
            },
          },
        )
        .then(resp => {
          const link = resp.data['link'];
          if (resp.status == 200) {
            if (link !== '') {
              this.$router.push(link);
            }
            return;
          }
          if (resp.status != 200) {
            alert('Não foi possível Cadastrar o Email. Tente novamente. ');
          }
        })
        .catch(() =>
          alert('Não foi possível Cadastrar o Email. Tente novamente. '),
        );
    },
  },
};
</script>

<style scoped>
.formulario {
  border: 1px solid rebeccapurple;
  box-shadow: rgba(194, 154, 231, 0.445);
}
.cor-violeta {
  color: #7e1dce;
}
.botao-comprar {
  text-align: center;
  margin-block: 40px;
}

.btn-comprar {
  padding: 20px 40px;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: #f5eef8;
  background: #aa00ff;
  border-radius: 30px;
  box-shadow: 3px 4px 3px #7a12c9;
  transition: 300ms;
}

a:link {
  color: #f5eef8;
}
a:visited {
  color: #f5eef8;
}
a:hover {
  color: #f5eef8;
  background: #e254ff;
  box-shadow: 6px 7px 7px #7a12c9;
  text-shadow: 1px 1px 2px #290742;
  cursor: default;
}
a:active {
  color: #f5eef8;
  background: #8913e2;
  box-shadow: 3px 3px 5px #7a12c9;
}
.vermelho {
  color: rgb(148, 16, 16);
}
</style>
