<template>
  <div class="container">
    <div class="row flex">
      <div class="col col-md-3">
        <img
          src="@/assets/saint_germain.jpeg"
          class="mx-auto d-block"
          height="300"
          alt="Saint Germain"
        />
      </div>
      <div class="col col-md-3">
        <img
          src="@/assets/shiva.jpeg"
          class="mx-auto d-block"
          height="300"
          alt="Shiva Parvati Ganesha"
        />
      </div>
      <div class="col col-md-3">
        <img
          src="@/assets/gaia.jpeg"
          class="mx-auto d-block"
          height="300"
          alt="Gaia"
        />
      </div>
      <div class="col col-md-3">
        <img
          src="@/assets/arcanjo_miguel.jpeg"
          class="mx-auto d-block"
          height="300"
          alt="Arcanjo Miguel"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Image4Padding',
};
</script>

<style scoped>
div.col {
  padding: 20px;
}
</style>
