<template>
  <div class="simbolos-azul">
    <NavBar navStyle="background-color: #4b31bd" />
    <div class="container fundo">
      <Title text="Ativação dos Símbolos da Alquimia Quântica da Chama Azul" />
      <h4 class="text-center opensans">
        “EU SOU LUZ, EU SOU PAZ, EU SOU AMOR, EU SOU A MANIFESTAÇÃO AQUI E AGORA
        DA VONTADE DIVINA!”
      </h4>
      <hr />
      <Title2
        text="Ativação do Símbolo do Infinito – Lemniscate"
        titleStyle="color: #4b31bd"
      />
      <Paragraph
        text="É ativado para ambientes, corpo físico, órgãos doentes, agindo onde deseja curar, trazendo equilíbrio, dissolvendo laços, rompendo as ligações negativas com pessoas, situações, padrão de pensamento, crenças limitantes, entre outros, libertando a pessoa para expressar o que é, corrigindo e equilibrando, em todos os níveis e dimensões de manifestação."
      />
      <Paragraph
        text="Este símbolo representa o Infinito, a Eternidade, e potencial divino ilimitado, sem começo e nem fim, seu nome é Lemniscate."
      />
      <hr />
      <Title2
        text="Ativação do Cálice Sagrado - Santo Graal"
        titleStyle="color: #4b31bd"
      />
      <Paragraph
        text="Código alquímico que quando ativado representa as forças e a harmonia entre o céu e a terra, entre o masculino e o feminino, a união de tudo que é necessário para a fecundação e nascimento de absolutamente tudo. Representa à união e o verdadeiro equilíbrio, abundância e renascimento, a busca por uma vida superior, a busca pela própria ascensão espiritual, a união do corpo com o espírito, libertando da maldade e da ignorância, clareando a mente para os propósitos divinos."
      />
      <hr />
      <Title2
        text="Ativação da Espada de Luz  - Excalibur"
        titleStyle="color: #4b31bd"
      />
      <Paragraph
        text="A Espada Excalibur, derrete tudo que seja contário a luz que está ao redor da pessoa, ao mesmo tempo em que derrete a negatividade, cria um escudo de proteção extremamente forte. Deve ser ativada para sua proteção e das pessoas com as quais convive, assim como também para a proteção dos ambientes por onde você circula ou trabalha, pode purificar seus corpos físico, etérico, emocional, mental e espiritual, os seus chacras. Na correria em que vivemos, é constante a perda do equilíbrio de nossas energias e o alimentar energias, pensamentos e sentimentos que nos deixam mal. Portanto, quando nos dispomos a tratar nossas dores e ao alinhamento dos chakras permitimos a revitalização de nossa força interna e reconstrução do corpo."
      />
      <hr />
      <Title2
        text="Ativação do Símbolo de Proteção - Yavith"
        titleStyle="color: #4b31bd"
      />
      <Paragraph
        text="O Yavyth é capaz de detectar a vibração de trabalhos de magia no campo magnético do atendido, atraindo para o trabalho as equipes adequadas para a atuação junto com o alquimista. É um código sagrado poderosos que remove chips, implantes negativos. Bloqueia e desmancha magia negra, feitiços e energias psíquicas. Pode ser utilizado na limpeza energética de residências, de fábricas, local de trabalho, veículos, doenças e mediunidade descontrolada."
      />
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'SimbolosAzul',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
