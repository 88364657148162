<template>
  <div class="botao">
    <a :href="url" class="btn-comprar" target="_blank">{{ texto }}</a>
  </div>
</template>

<script>
export default {
  name: 'Botao',
  props: {
    texto: String,
    url: String,
  },
};
</script>

<style scoped>
.botao-comprar {
  text-align: center;
  margin-block: 40px;
}

.btn-comprar {
  padding: 20px 40px;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: #f5eef8;
  background: #aa00ff;
  border-radius: 30px;
  box-shadow: 3px 4px 3px #7a12c9;
  transition: 300ms;
}

a:link {
  color: #f5eef8;
}
a:visited {
  color: #f5eef8;
}
a:hover {
  color: #f5eef8;
  background: #e254ff;
  box-shadow: 6px 7px 7px #7a12c9;
  text-shadow: 1px 1px 2px #290742;
  cursor: default;
}
a:active {
  color: #f5eef8;
  background: #8913e2;
  box-shadow: 3px 3px 5px #7a12c9;
}
</style>
