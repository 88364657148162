<template>
  <div class="cadastre-se-valor">
    <h4 class="opensans anima">
      Cadastre-se já e receba
      <span class="destacado1">UM PRESENTE SURPRESA!</span> e um
      <span class="destacado1">SUPER DESCONTO!</span>
    </h4>
    <h4 class="opensans anima">
      de R$
      <span class="cortado">809,00</span>
      por apenas 10 x de
      <span class="destacado"> 49,70 </span>
    </h4>
  </div>
</template>

<script>
export default {
  name: 'CadastreSeValor',
};
</script>

<style scoped>
.cadastre-se-valor {
  padding: 20px;
  background-color: rgb(223, 221, 247);
  border-radius: 20px 20px;
  color: white;
  box-shadow: 4px 4px 10px rgb(198, 105, 241);
  transition: 400ms;
}
.cadastre-se-valor:hover {
  box-shadow: 8px 8px 10px rgb(198, 105, 241);
}

h4 {
  color: #7e1dce;
  text-align: center;
}

.cortado {
  color: #999999;
  text-decoration: line-through;
}

.destacado {
  color: #dd22ff;
  font-weight: 700;
  font-size: 1.3em;
  transition: 400ms;
}

.destacado:hover {
  font-size: 1.4em;
  padding-bottom: 0px;
}

.destacado1 {
  color: #dd22ff;
  font-weight: 600;
  transition: 400ms;
  font-size: 0.9em;
}

.destacado1:hover {
  font-size: 1em;
}
</style>
