<template>
  <div class="titulo-principal">
    <br />
    <h1 class="text-center sacramento">
      {{ text }}
    </h1>
    <br />
  </div>
</template>

<script>
export default {
  name: 'TituloPrincipal',
  props: {
    text: String,
  },
};
</script>

<style scoped>
h1 {
  font-size: 3em;
}
</style>
