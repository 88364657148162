<template>
  <div class="gerador-violeta">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container fundo">
      <Title text="Gerador de Força Radiônica da Chama Violeta " />
      <Title2 text="O que é?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="O Gerador de Força Radiônica de Saint Germain é um motor Gerador/Captador de Forças Cósmicas Universais, que através da energia dos cristais e da força mental, é programado para gerar e transmitir essas energias para fins determinados, na transmutação e cura de doenças e situações, que estarão sendo tratados, recebendo essas irradiações de cura cósmica de altíssima frequência pelo tempo que o Alquimista o programar, reverberando em muitas enfermidades do espírito, do corpo, da mente e das emoções."
      />
      <Title2 text="Como funciona a Terapia?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="O Gerador de Energia de Força Radiônica é um Sistema que capta, gera e sustenta energia para o funcionamento de nossos corpos, em todas as dimensões e linhas de tempo-espaço do histórico da pessoa, em que se apresentem, distribuindo energia para que todas as funções recebam o quantum de força necessária para que funcionem em perfeita harmonia, durante o tratamento e após, limpando doenças, desequilíbrios, Registros Akáshicos, crenças limitantes, e também pode ser ativado para questões estéticas, situações judiciais e de relacionamentos."
      />
      <Paragraph
        text="Tratamento à distância por 30 dias, reativado diariamente."
      />
      <Title2 text="Como é o Tratamento?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="O bem-estar e a boa saúde acontecem quando a energia espiritual que flui livremente através de nossos corpos. Quando a substância cármica se solidifica, é como se ocorresse o endurecimento de artérias em nossos corpos espirituais. Quando ressonamos com a negatividade, gradualmente nos tornamos ela, a não ser que, façamos algo para melhorar."
      />
      <Paragraph
        text="É realizada a consulta e atendimento, presencial ou à distância, para o recebimento das orientações a serem seguidas e a realização de um Questionário de Análise Terapêutica e a coleta de informações e situações vindas através das queixas da pessoa."
      />
      <Paragraph
        text="Ativado o Gerador de Força Radiônica da Chama Violeta para o tratamento por 30 dias, à distância, reativado diariamente, com altas frequências energéticas dos sete raios cósmicos em conexão com os sete Elohins, potencializado com a energia do Raio Laser da Chama Violeta do Mestre Saint Germain."
      />
      <Paragraph
        text="São realizados Mantras e Decretos específicos e comandos quânticos durante o processo de tratamento e a disposição dos cristais ativados e nomeadas todas as situações e informações a serem trabalhadas por trinta dias reativadas diariamente, para os chakras, campo magnético, situações pontuais, relacionamentos, absorvendo e transmutando as energias negativas, auxíliando na abertura das linhas de comunicação entre você e pessoas, atuando para desbloquear os caminhos, limpando os obstáculos à sua frente e auxiliando em sua ascensão."
      />
      <br />
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'GeradorVioleta',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
