<template>
  <div class="title">
    <br />
    <h1 class="text-center sacramento">
      {{ text }}
    </h1>
    <br />
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    text: String,
  },
};
</script>
