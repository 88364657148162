<template>
  <div class="cristais-etericos">
    <NavBar navStyle="background-color: #3191bd" />
    <div class="container fundo">
      <br />
      <img
        src="@/assets/cristais_escola.jpeg"
        class="img-fluid mx-auto d-block"
        width="260"
        alt="Cristais Chakras"
      />
      <Title text="O que são os Cristais Etéricos?" />
      <Paragraph
        text="São terapêuticos, condutores e amplificadores de energias, neutralizando energias negativas e potencializando as
            energias positivas, quando bem utilizadas, refletem-se nos nossos pensamentos, sensações, palavras e ações diárias."
      />
      <Paragraph
        text="Possuem uma potência de energia 400% maior que a energia dos cristais físicos, podendo potencializar a energia do
            cristal físico quando ativado por um terapeuta com iniciação."
      />
      <Paragraph
        text="Podem ser ativados os Cristais Etéricos instantaneamente para situações pontuais e desajustes energéticos, de acordo com
            cada chacra, como também são utilizados para outros tratamentos como a Acupuntura Etérica, Elixires de Cristais e os
            Florais Etéricos."
      />
      <Paragraph
        text="Os Cristais Físicos vêm do centro da Terra e possuem uma energia muito forte. Cada pessoa escolhe seus cristais de
            acordo com as necessidades que apresenta naquele momento, muitas vezes, sendo escolhidos pelo próprio cristal, ou o Deva
            deste cristal, que é o protetor que cada cristal contém em seu interior."
      />
      <Paragraph
        text="Graças a sua estrutura perfeita e a simetria de seus átomos, são capazes de limpar, curar e energizar o campo áurico das
            pessoas, de animais, de ambientes, elevando sua frequência vibratória."
      />
      <Title2
        text="Utilize os cristais para auxiliar na Alquimia de sua Alma!"
        titleStyle="color: #3191bd"
      />
      <br />
    </div>
    <hr />
    <Contato
      text="Agende um atendimento via Whatsapp"
      btnLink="https://api.whatsapp.com/send?phone=5554996414661"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'CristaisEtericos',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
