<template>
  <div class="container-fluid">
    <br />
    <h3 class="text-center mb-4 opensans">Minha Jornada</h3>
    <div class="container">
      <h1 class="text-center mb-4 sacramento">Milena Vidmar</h1>
    </div>
    <div class="container">
      <img
        src="@/assets/milena.jpg"
        class="img-fluid mx-auto d-block"
        width="184"
        height="254"
        alt="Milena Vidmar - Terapeuta Holística"
      />
    </div>
    <br />
    <div class="container">
      <h1 class="text-center mb-4 sacramento">Terapeuta Holística</h1>
    </div>
    <div class="justify-content-center align-items-center row">
      <div class="col-md-6 offset-md-2 align-self-center">
        <ul class="list-unstyled opensans">
          <li>
            <ul>
              <li>
                Grã-Sacerdotisa da Ordem de São Miguel – São Miguel Arcanjo
              </li>
              <li>Mestra Terapeuta em Florais Etéricos de Arcanjo Miguel</li>
              <li>
                Terapeuta em Cristais Etéricos, Elexir de Cristais e Acupuntura
              </li>
              <li>
                Psicopedagoga Institucional e Clínica FSF - São Fidélis - RJ
              </li>
              <li>
                Alquimista Quântica da Chama Violeta – Mestre Saint Germain
              </li>
              <li>Alquimista Quântica da Chama Azul – Arcanjo Miguel</li>
              <li>Guardiã do Esquadrão da Luz – Apometria Arcturiana</li>
              <li>Afiliada do Colegiado de Guardiões da Humanidade</li>
              <li>Criadora da Escola Alquimia Quântica</li>
              <li>
                Registro Profissional de Terapeuta Holística ABRATH – CRTH-BR
                9228
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <img src="@/assets/Selo-abrath-2.png" alt="Terapeuta Crenciado ABRATH" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Journey',
};
</script>
