<template>
  <div class="container">
    <div class="row">
      <div class="imagem">
        <img
          src="@/assets/logo_400.png"
          alt="Escola Alquimia Quântica - Cursos e Terapias Holísticas"
          width="160"
          height="160"
        />
      </div>
    </div>
    <div class="row">
      <div class="imagem">
        <img
          src="@/assets/escola_alquimia_quantica_black.png"
          alt="Escola Alquimia Quântica - Cursos e Terapias Holísticas"
          height="62"
        />
      </div>
    </div>
    <div class="row">
      <span class="sacramento grande"
        >Neste Novo Milênio, Queremos Trazer Luz a sua Vida!</span
      >
    </div>
    <div class="row">
      <span
        >Site:
        <a href="https://escolaalquimiaquantica.com.br"
          >escolaalquimiaquantica.com.br</a
        ></span
      >
    </div>
    <div class="row">
      <span
        >Email:
        <a href="mailto:escolaalquimiaquantica@gmail.com?subject=Contato"
          >escolaalquimiaquantica@gmail.com</a
        ></span
      >
    </div>
    <div class="row">
      <span
        >Whatsapp:
        <a href="https://api.whatsapp.com/send?phone=5554996414661"
          >(54) 99641-4661</a
        ></span
      >
    </div>
    <br />
  </div>
</template>

<script>
export default {
  name: 'IdentBottom',
};
</script>

<style scoped>
div.imagem img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
div span {
  text-align: center;
}
div span.grande {
  font-size: 1.6em;
  font-weight: 500;
}
</style>
