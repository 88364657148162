<template>
  <div class="chama-violeta">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container fundo">
      <Title
        text="Ativações da Alquimia Quântica da Chama Violeta - Mestre Saint Germain"
      />
      <img
        src="@/assets/saint_germain.jpeg"
        class="img-fluid mx-auto d-block"
        width="220"
        alt="Mestre Saint Germain"
      />
      <br />
      <Title2 text="O que é?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="A Alquimia Quântica da Chama Violeta é um Sistema de Cura Cósmica, o Reiki Alquímico, que vem ampliar o acesso às energias transmutadoras e curadoras e todas as suas formas de aplicação quando manipuladas em tratamentos de cura energética, aumentando o poder de atuação dessas energias mais compatíveis com a realidade atual, da nova frequência do Planeta, em razão desta ser a própria energia de todo o Plano Divino para a Era de Ouro de Aquário, do Chohan, Amado Mestre Saint Germain."
      />
      <Paragraph
        text="O Mestre Saint Germain explica que quando nosso corpo físico e os espirituais se tornam obstruídos por energia negativa e pelo carma, diminui a vibração dos elétrons em nossos quatro corpos inferiores. Então começamos nos identificar mais com a negatividade e menos com a pura energia cósmica que vem da nossa Presença Divina e, acabamos ficando doentes."
      />
      <Paragraph
        text="Todo momento em nossas vidas criamos carma positivo ou negativo. Quando qualificamos a nossa energia com o rancor, o ressentimento, o ódio, a tristeza, o medo, a crítica, a condenação ou julgamento, com energias de baixa frequência, ela fica muito pesada para subir até Deus, de onde veio, então, ela segue a Lei do círculo, Lei do Retorno, dá a volta por todo o planeta, unindo-se a outras energias iguais a ela. Assim, ela volta multiplicada muitas vezes para quem as enviou. Esta energia pesada volta e acumula-se nas células do indivíduo e em torno dele próprio. "
      />
      <Paragraph
        text="Com o acúmulo da negatividade em nosso ser, acabamos por atrair variados tipos de problemas, doenças, acidentes, desentendimentos, desequilíbrios emocionais e mentais, dentre tantas outras situações conflituosas."
      />
      <Title2 text="Para que serve?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="A Aplicação da Chama Violeta nos leva ao renascimento, a descoberta de quem nós somos, independente do que os outros pensam de  nós, mergulhamos em nosso interior descobrindo nossas dores, crenças limitantes e padrões de pensamento enraizados durante nossa existência nesta ou em outras vidas, ficando gravadas em nossos Registros Akáshicos, isto é, nos registros de nossa alma, desde a sua criação por Deus Pai e Mãe."
      />
      <Paragraph
        text="Essa energia entra nos átomos e elétrons, realizando uma limpeza profunda em vários níveis de nosso ser e desta forma ocorrendo os saltos quânticos, na medida em que vamos realizando os processos de terapias com esta energia, a cada vez mais abertura e equilíbrio de nosso chacras e das diversas áreas de nossas vidas. "
      />
      <Paragraph
        text="Amplia a capacidade de acesso as energias cósmicas que chegam ao planeta, carregando a capacidade de transmutar e curar ao mesmo tempo. É uma das chamas mais completas dos raios sagrados, carregando as energias dos outros raios, pois o núcleo desta chama é branca, do puro Amor e Luz de Deus."
      />
      <Paragraph
        text="Atua para a Transmutação das negatividades, doenças e carmas humanos através da aplicação das energias da Chama Violeta, do 7º Raio Crístico de Deus, do Amado Mestre Saint Germain, para a Era de Aquário, dissolvendo os condicionamentos físicos, mentais, emocionais e espirituais, auxiliando na ascensão pessoal e na ampliação da consciência espiritual, elevando as vibrações para a 5ª dimensão, de acordo com o uso constante e manutenção destas energias em nosso campo áurico, influenciando nos pensamentos, ações, hábitos e sentimentos diários, de forma bem prática e perceptível logo no início dos tratamentos."
      />
    </div>
    <hr />
    <br />
    <div class="container">
      <div class="container px-8">
        <Title
          text="Tratamentos com as Terapias Holísticas da Alquimia Quântica da Chama
          Violeta do Mestre Saint Germain"
        />
        <div class="row gx-5">
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="aplicacao_violeta.jpeg"
                btnLink="/reiki_violeta"
                text="Ativação do Reiki Alquímico da Chama Violeta"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="gerador_violeta.jpeg"
                btnLink="/gerador_violeta"
                text="Gerador de Força Radiônica da Chama Violeta "
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="laser_violeta.jpeg"
                btnLink="/laser_violeta"
                text="Aplicação do Raio Laser da Chama Violeta"
              />
            </div>
          </div>
          <div class="col">
            <div class="p-3">
              <Card
                imgSrc="simbolos_violeta.jpeg"
                btnLink="/simbolos_violeta"
                text="Ativação dos Símbolos da Alquimia Quântica da Chama Violeta"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Card from '@/components/Card.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'ChamaVioleta',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Card,
    Contato,
  },
};
</script>
