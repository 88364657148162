<template>
  <div class="card opensans" style="width: 18rem">
    <img :src="coverUri" class="card-img-top" :alt="imgAlt" />
    <div class="card-body">
      <p class="card-text">{{ text }}</p>
      <a :href="btnLink" class="btn btn-primary stretched-link">Saiba mais</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    imgSrc: String,
    btnLink: String,
    text: String,
    imgAlt: String,
  },
  computed: {
    coverUri() {
      return require('../assets/' + this.imgSrc);
    },
  },
};
</script>
