<template>
  <div class="assinatura-semestral">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container fundo">
      <br />
      <HRImage />
      <TituloPrincipal text="Plano de Assinatura Semestral Terapêutica" />
      <Paragraph
        text="Nossos produtos e serviços terapêuticos são para você mulher conectada as novas
              energias de Gaia, que já despertou para a nova consciência e deseja elevar suas
              frequências vibratórias e continuar seu processo de ascensão pessoal."
      />
      <Paragraph
        text="Para você que busca a cura para suas dores da sua alma, limpar energias negativas e
              transmutar todo o tipo de desequilíbrios de seu corpo, mente, emoções e elevar-se
              espiritualmente para a 5ª dimensão."
      />
      <Paragraph
        text="Muitas doenças são causadas por nós mesmos, por situações e conflitos que criamos
              com nosso comportamento diante da vida, nossa boca, nosso pensamento, nossas ações
              e diante desta verdade, foram canalizadas vários sistemas de cura, pelos Mestres
              Ascensos e é possível fazê-los com um comando mental do iniciado no sistema
              recebendo a autorização do Universo e dos diversos elementos para manusear as
              energias harmônicas que restabelecerão o equilíbrio do corpo, mente, emoções e
              espírito, de acordo com a doença ou necessidade apresentada pelo Ser que as utilizará
              para tratamento."
      />
      <br />
      <img
        src="@/assets/banner_youtube.png"
        class="img-fluid mx-auto d-block"
        width="600"
        alt="Escola Alquimia Quantica Terapias"
      />
      <br />
      <Paragraph
        text="A Escola Alquimia Quântica quer ajudar a você se liberar de crenças limitantes,
              energias estagnadas e tornar você cada dia mais próspera e feliz!"
      />
      <Paragraph
        text="Assine já e adquira o combo das Aplicações Terapêuticas com a Alquimia Quântica da
              Chama Violeta e Azul, e se presentei com amor próprio, autoestima, autoconfiança e
              outras virtudes que irão fazer de você, uma nova mulher, a mulher empodeirada e com
              frequências sutis que alcançará novas experiências de manifestação de seus desejos."
      />
      <br />
      <AssineJa />
      <BotaoComprar />
      <hr />
      <ProdutosAssinatura />
      <br />
      <CadastreSeValor />
      <BotaoComprar />
      <hr />
      <br />
      <img
        src="@/assets/aplicacao_violeta.jpeg"
        class="img-fluid mx-auto d-block"
        width="400"
        alt="Reiki Chama Violeta"
      />
      <br />
      <Title text="1º Mês: Ativação do Reiki Alquímico da Chama Violeta" />
      <br />
      <Title2 text="O QUE É?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="A Alquimia Quântica da Chama Violeta é um Sistema de Cura Avançada muito
              profunda, de Transmutação, Cura e Energização de Registros Akáshicos, Limpeza
              Cármica e do Ego, trazendo as energias do Amor, da Libertação, do Perdão e da Cura."
      />
      <Paragraph
        text="É uma Terapia Energética transmitida através do toque com as mãos para
              reabastecer instantaneamente a nossa energia vital. Sua prática pode ser empregada para
              promover o equilíbrio emocional e conectar a pessoa com o seu Eu Interior. Como
              complemento nos tratamentos médicos mais agressivos, reduzindo os sintomas e
              curando as causas, possibilitando o desenvolvimento da autoestima, segurança e
              revitalização geral, como aumento do sistema imunológico, alívio e redução de dores
              físicas e sentimentais como depressão, angústias, stress e ansiedade, dentre outros
              benefícios e situações diárias conflitantes, inclusive para tratamentos estéticos."
      />
      <br />
      <Title2 text="COMO FUNCIONA A TERAPIA?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É um Sistema de Cura Cósmica, que é ativado à distância, com a
              utilização e ativação dos Símbolos da Alquimia Quântica da Chama Violeta, cristais
              físicos, energias Etéricas Universais, canalizadas pelo Alquimista iniciado, com o
              ancoramento da Hierarquia Espiritual do 7º Raio Violeta, do Mestre Saint Germain, que
              atuam diretamente nos planos onde se manifestam nossos corpos e nas causas das
              doenças."
      />
      <br />
      <Title2 text="COMO É O TRATAMENTO?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É realizada a consulta e atendimento à distância, para a troca de
              informações necessárias para esse fim, e a realização de um Questionário de Análise
              Terapêutica e envio das orientações a serem seguidas."
      />
      <Paragraph
        text="O paciente se sintoniza com as energias, em horário agendado, em ambiente
              tranquilo e recebe em torno de 45 minutos de tratamento intenso, com a ativação do
              Laboratório Alquímico e dos quatros elementos."
      />
      <br />
      <Paragraph text="📲 Atendimento à distância." />
      <BotaoComprar />
      <hr />
      <br />
      <img
        src="@/assets/gerador_violeta.jpeg"
        class="img-fluid mx-auto d-block"
        width="400"
        alt="Gerador Chama Violeta"
      />
      <br />
      <Title
        text="2º Mês: Ativação do Gerador de Força Radiônica de Arcanjo Miguel"
      />
      <br />
      <Title2 text="O QUE É?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="O Gerador de Força Radiônica é um motor Gerador/Captador de Forças
              Cósmicas Universais, que através da disposição dos cristais, da força mental e da
              canalização de energias Etéricas é ativado e programado para gerar e transmitir esses
              raios para determinadas situações e pessoas, realizando a limpeza profunda de energias
              densas e a manutenção das energias em nosso campo áurico."
      />
      <br />
      <Title2 text="COMO FUNCIONA A TERAPIA?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É um Sistema de Cura Cósmica, que é ativado à distância, com a utilização e
              ativação dos símbolos da Alquimia Quântica da Chama Azul, cristais físicos, energias
              Etéricas Universais, canalizadas pelo Alquimista iniciado, com o ancoramento da
              Hierarquia Espiritual do 1º Raio Azul, de Arcanjo Miguel e Mestre El Morya, que
              atuam diretamente nos planos onde se manifestam nossos corpos."
      />
      <br />
      <Title2 text="COMO É O TRATAMENTO?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É realizada a consulta à distância, para o recebimento das
              orientações a seguir e solicitação de alguns dados pessoais."
      />
      <Paragraph
        text="Ativado o Gerador para tratamento por 1 hora, à distância, com altas frequências
              energéticas e só pode ser realizado a cada vinte e um dias para a mesma pessoa."
      />
      <Paragraph
        text="São realizados Mantras e Decretos específicos e comandos quânticos durante o processo
              de tratamento e a disposição dos cristais ativados."
      />
      <br />
      <Title2 text="PARA QUE SERVE?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="Tratamento fortíssimo que retira todos os bloqueios trazidos desde seus
              Registros Akáshicos, isto é, de suas outras vidas e experiências dos corpos físico,
              mental, emocional e espiritual, trazidas através do sistema familiar, registrados no
              DNA, fazendo uma varredura destas energias em todos os níveis e dimensões de
              manifestação e em todas as linhas de tempo e espaço. Influenciando nos pensamentos,
              ações, hábitos e sentimentos diários e limpeza espiritual do campo energético, corte de
              laços negativos, transmutação de miasmas, mucos, vírus astrais, desobsessão."
      />
      <Paragraph text="Todos os tratamentos com data pré-agendada." />
      <BotaoComprar />
      <hr />
      <br />
      <img
        src="@/assets/florais.jpeg"
        class="img-fluid mx-auto d-block"
        width="400"
        alt="Florais Arcanjo Miguel"
      />
      <br />
      <Title
        text="3º Mês: Tratamento com Florais Físicos escolhidos de acordo com sua verdade e desejo
              Personalizado e enviado após sua escolha"
      />
      <br />
      <Title2
        text="O que são os Florais Etéricos?"
        titleStyle="color: #7e1dce"
      />
      <Paragraph
        text="Os Florais Etéricos vêm atuar nos planos físico, mental, emocional e espiritual, trazendo
              de volta o equilíbrio e recuperação da saúde, num efeito sistêmico. Além de
              trabalharem nestes planos, realizam uma limpeza de emoções negativas criadas ou
              herdadas dos antepassados através do Sistema Familiar. Os Florais Etéricos servem para
              clarear o que precisa ser transmutado em nossa vida. As imagens, pensamentos
              negativos e crenças limitantes armazenados no subconsciente, se manifestam na vida do
              indivíduo, criando, muitas vezes, bloqueios, excessos e desiquilíbrios. Cada ser contém
              inúmeros elementos harmônicos dentro do próprio organismo para restabelecer seu
              equilíbrio e, com o uso dos florais, o corpo começa produzir enzimas capazes de
              fortalecer o Sistema Nervoso e o Sistema Endócrino que estão ligados aos Corpos Sutis,
              Chakras e Meridianos, realizando limpezas dessas crenças e registros provenientes de
              outras vidas, nos níveis dos Registros Akáshicos, energias negativas e da aura. O
              Sistema dos Florais Etéricos é um Método que funciona independente da Fé, Credo,
              Filosofia ou Religião."
      />
      <br />
      <Title2
        text="Busque as curas para sua alma!"
        titleStyle="color: #7e1dce"
      />
      <BotaoComprar text="Clique aqui para Comprar" url="" />
      <hr />
      <br />
      <img
        src="@/assets/gerador_violeta.jpeg"
        class="img-fluid mx-auto d-block"
        width="400"
        alt="Gerador Chama Violeta"
      />
      <br />
      <Title
        text="4º Mês: Ativação do Gerador de Força Radiônica da Chama Violeta"
      />
      <br />
      <Title2 text="O QUE É?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="O Gerador de Força Radiônica de Saint Germain é um motor Gerador/Captador
              de Forças Cósmicas Universais, que através da energia dos cristais e da força mental, é
              programado para gerar e transmitir essas energias para fins determinados, na
              transmutação e cura de doenças e situações, que estarão sendo tratados, recebendo essas
              irradiações de cura cósmica de altíssima frequência pelo tempo que o Alquimista o
              programar, reverberando em muitas enfermidades do espírito, do corpo, da mente e das
              emoções."
      />
      <br />
      <Title2 text="COMO FUNCIONA A TERAPIA?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="O Gerador de Energia de Força Radiônica é um Sistema que capta, gera e
              sustenta energia para o funcionamento de nossos corpos, em todas as dimensões e linhas
              de tempo-espaço do histórico da pessoa, em que se apresentem, distribuindo energia
              para que todas as funções recebam o quantum de força necessária para que funcionem
              em perfeita harmonia, durante o tratamento e após, limpando doenças, desequilíbrios,
              Registros Akáshicos, crenças limitantes, e também pode ser ativado para questões
              estéticas, situações judiciais e de relacionamentos"
      />
      <Paragraph
        text="Tratamento à distância por 30 dias, reativado diariamente."
      />
      <br />
      <Title2 text="COMO É O TRATAMENTO?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="O bem-estar e a boa saúde acontecem quando a energia espiritual que flui
              livremente através de nossos corpos. Quando a substância Kármica se solidifica, é como
              se ocorresse o endurecimento de artérias em nossos corpos espirituais. Quando
              ressonamos com a negatividade, gradualmente nos tornamos ela, a não ser que, façamos
              algo para melhorar."
      />
      <Paragraph
        text="É realizada a consulta e atendimento à distância, para o
              recebimento das orientações a serem seguidas e a realização de um Questionário de
              Análise Terapêutica e a coleta de informações e situações vindas através das queixas da
              pessoa."
      />
      <Paragraph
        text="Ativado o Gerador de Força Radiônica da Chama Violeta para o tratamento por
              30 dias, à distância, reativado diariamente, com altas frequências energéticas dos sete
              raios cósmicos em conexão com os sete Elohins, potencializado com a energia do Raio
              Laser da Chama Violeta do Mestre Saint Germain."
      />
      <Paragraph
        text="São realizados Mantras e Decretos específicos e comandos quânticos durante o
              processo de tratamento e a disposição dos cristais ativados e nomeadas todas as
              situações e informações a serem trabalhadas por trinta dias reativadas diariamente, para
              os chakras, campo magnético, situações pontuais, relacionamentos, absorvendo e
              transmutando as energias negativas, auxiliando na abertura das linhas de comunicação
              entre você e pessoas, atuando para desbloquear os caminhos, limpando os obstáculos à
              sua frente e auxiliando em sua ascensão."
      />
      <BotaoComprar />
      <hr />
      <br />
      <img
        src="@/assets/cristais_chakras.jpeg"
        class="img-fluid mx-auto d-block"
        width="400"
        alt="Cristais Chakras"
      />
      <br />
      <Title
        text="5º Mês: Tratamento com o Kit 7 chakras de Cristais Físicos potencializados com os
              cristais etéricos em cada um, enviados junto as orientações de uso para limpeza,
              equilíbrio e energização dos chakras"
      />
      <br />
      <Title2 text="O que são os Cristais?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="São terapêuticos, condutores e amplificadores de energias, neutralizando
              energias negativas e potencializando as energias positivas, quando bem utilizadas,
              refletem-se nos nossos pensamentos, sensações, palavras e ações diárias."
      />
      <Paragraph
        text="Possuem uma potência de energia 400% maior que a energia dos cristais físicos,
              podendo potencializar a energia do cristal físico quando ativado por um terapeuta com
              iniciação."
      />
      <Paragraph
        text="Podem ser ativados os Cristais Etéricos instantaneamente para situações pontuais
              e desajustes energéticos, de acordo com cada chacra, como também são utilizados para
              outros tratamentos como a Acupuntura Etérica, Elixires de Cristais e os Florais Etéricos."
      />
      <Paragraph
        text="Os Cristais Físicos vêm do centro da Terra e possuem uma energia muito forte.
              Cada pessoa escolhe seus cristais de acordo com as necessidades que apresenta naquele
              momento, muitas vezes, sendo escolhidos pelo próprio cristal, ou o Deva deste cristal,
              que é o protetor que cada cristal contém em seu interior."
      />
      <Paragraph
        text="Graças a sua estrutura perfeita e a simetria de seus átomos, são capazes de
              limpar, curar e energizar o campo áurico das pessoas, de animais, de ambientes,
              elevando sua frequência vibratória."
      />
      <br />
      <Title2
        text="Utilize os cristais para auxiliar na Alquimia de sua Alma!"
        titleStyle="color: #7e1dce"
      />
      <BotaoComprar />
      <hr />
      <br />
      <div class="row">
        <div class="col col-md-6">
          <img
            src="@/assets/simbolos_azul.jpeg"
            class="img-fluid mx-auto d-block"
            width="400"
            alt="Ativacao Simbolos Chama Azul"
          />
        </div>
        <div class="col col-md-6">
          <img
            src="@/assets/reiki_azul.jpeg"
            class="img-fluid mx-auto d-block"
            width="400"
            alt="Reiki Chama Azul"
          />
        </div>
      </div>
      <br />
      <Title
        text="6º Mês: Limpeza energética para sua residência com os Símbolos da Alquimia Quântica
              da Chama Azul + Ativação do Reiki Alquímico da Chama Azul"
      />
      <br />
      <Title2 text="O QUE É?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="A Alquimia Quântica da Chama Azul é um Sistema de Cura Avançada muito profunda,
              de limpeza espiritual do campo energético, corte de laços negativos, transmutação de
              miasmas, mucos, vírus astrais, desobsessão."
      />
      <br />
      <Title2 text="COMO FUNCIONA A TERAPIA?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É um Sistema de Cura Cósmica, através da canalização das Energias Etéricas
              Universais. O Corte de laços negativos e o Reiki Alquímico são realizados através da
              imposição de mãos, que é aplicado à distância, com a ativação dos
              símbolos da Alquimia Quântica da Chama Azul e o uso dos cristais físicos pelo
              Alquimista iniciado, com o ancoramento da Hierarquia Espiritual do 1º Raio Azul, de
              Arcanjo Miguel e Mestre El Morya."
      />
      <br />
      <Title2 text="COMO É O TRATAMENTO?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É realizada a consulta e atendimento à distância, para o recebimento das
              orientações a serem seguidas e a realização de um Questionário de Análise Terapêutica."
      />
      <Paragraph
        text="O paciente se sintoniza com as energias, em horário agendado, em ambiente tranquilo e
              recebe em torno de 45 minutos de tratamento intenso, com a ativação do Laboratório
              Alquímico e dos quatros elementos."
      />
      <br />
      <Title2 text="PARA QUE SERVE?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="Ativa, amplifica e expande a capacidade de utilização do Poder, da Força, Fé, Vontade
              Divina, Coragem, Disciplina e Determinação para a concretização de metas, para fins
              individuais e coletivos."
      />
      <Paragraph
        text="Alinha os corpos etéricos, mental e espiritual, promovendo a conexão entre o plano
              físico e o espiritual, aumentando assim a riqueza espiritual, se reflete em um maior
              equilíbrio no direcionamento da vida na materialidade."
      />
      <Paragraph
        text="Auxilia a retirar as máscaras da personalidade, dissolvendo velhos padrões de repressão,
              aflorando a expressão verbal de pensamentos e sentimentos, combatendo o estresse
              mental e emocional."
      />
      <Paragraph
        text="Desperta a consciência para assumir as responsabilidades em resolver os problemas,
              tendo a compreensão inteligente em aplicar na sua própria vida para solucionar aqueles
              problemas que vem se arrastando e sendo deixados pra depois, criando um ciclo vicioso."
      />
      <Paragraph
        text="Dessa maneira, adquirindo força, coragem e confiança necessárias para prosseguir,
              galgando degrau a degrau, conseguindo o seu próprio domínio consciente sobre o Eu
              exterior, o mundo interior, seus pensamentos e consequentemente suas ações, se
              tornando mais disciplinado e ampliando o poder de sua Fé, através de pequenos e
              constantes movimentos e conquistas diárias, nos reconectando ao nosso Eu divino."
      />
      <br />
      <Title text="+ Ativações dos Símbolos da Chama Azul" />
      <Title2
        text="Ativação do Símbolo do Infinito – Lemniscate"
        titleStyle="color: #7e1dce"
      />
      <Paragraph
        text="É ativado para ambientes, corpo físico, órgãos doentes, agindo onde deseja curar, trazendo equilíbrio, dissolvendo laços, rompendo as ligações negativas com pessoas, situações, padrão de pensamento, crenças limitantes, entre outros, libertando a pessoa para expressar o que é, corrigindo e equilibrando, em todos os níveis e dimensões de manifestação."
      />
      <Paragraph
        text="Este símbolo representa o Infinito, a Eternidade, e potencial divino ilimitado, sem começo e nem fim, seu nome é Lemniscate."
      />
      <Title2
        text="Ativação do Cálice Sagrado - Santo Graal"
        titleStyle="color: #7e1dce"
      />
      <Paragraph
        text="Código alquímico que quando ativado representa as forças e a harmonia entre o céu e a terra, entre o masculino e o feminino, a união de tudo que é necessário para a fecundação e nascimento de absolutamente tudo. Representa à união e o verdadeiro equilíbrio, abundância e renascimento, a busca por uma vida superior, a busca pela própria ascensão espiritual, a união do corpo com o espírito, libertando da maldade e da ignorância, clareando a mente para os propósitos divinos."
      />
      <Title2
        text="Ativação da Espada de Luz  - Excalibur"
        titleStyle="color: #7e1dce"
      />
      <Paragraph
        text="A Espada Excalibur, derrete tudo que seja contário a luz que está ao redor da pessoa, ao mesmo tempo em que derrete a negatividade, cria um escudo de proteção extremamente forte. Deve ser ativada para sua proteção e das pessoas com as quais convive, assim como também para a proteção dos ambientes por onde você circula ou trabalha, pode purificar seus corpos físico, etérico, emocional, mental e espiritual, os seus chacras."
      />
      <Paragraph
        text="Na correria em que vivemos, é constante a perda do equilíbrio de nossas energias e o alimentar energias, pensamentos e sentimentos que nos deixam mal. Portanto, quando nos dispomos a tratar nossas dores e ao alinhamento dos chakras permitimos a revitalização de nossa força interna e reconstrução do corpo. "
      />
      <Title2
        text="Ativação do Símbolo de Proteção - Yavith"
        titleStyle="color: #7e1dce"
      />
      <Paragraph
        text="O Yavyth é capaz de detectar a vibração de trabalhos de magia no campo magnético do atendido, atraindo para o trabalho as equipes adequadas para a atuação junto com o alquimista. É um código sagrado poderosos que remove chips, implantes negativos. Bloqueia e desmancha magia negra, feitiços e energias psíquicas. Pode ser utilizado na limpeza energética de residências, de fábricas, local de trabalho, veículos, doenças e mediunidade descontrolada."
      />
      <br />
      <CadastreSeValor />
      <br />
      <BotaoComprar />
      <br />
      <Title2
        text="Garantia de Satisfação durante o Processo!"
        titleStyle="color: #7e1dce; text-align: center"
      />
      <br />
      <hr />
      <Title
        text="Seu Eu Sou abrirá novas portas para a percepção de sua realidade e trará mais
              abundância e prosperidade para sua vida!"
      />
    </div>
    <Image4Padding />
    <div class="container">
      <hr />
      <Journey />
      <hr />
    </div>
    <IdentBottom />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import TituloPrincipal from '@/components/TituloPrincipal.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Paragraph from '@/components/Paragraph.vue';
import IdentBottom from '@/components/IdentBottom.vue';
import BotaoComprar from '@/components/BotaoComprar.vue';
import CadastreSeValor from '@/components/CadastreSeValor.vue';
import AssineJa from '@/components/AssineJa.vue';
import Journey from '@/components/Journey.vue';
import HRImage from '@/components/HRImage.vue';
import Image4Padding from '@/components/Image4Padding.vue';
import ProdutosAssinatura from '@/components/ProdutosAssinatura.vue';

export default {
  name: 'AssinaturaSemestral',
  components: {
    TituloPrincipal,
    Title,
    Title2,
    NavBar,
    Paragraph,
    IdentBottom,
    BotaoComprar,
    CadastreSeValor,
    AssineJa,
    Journey,
    HRImage,
    Image4Padding,
    ProdutosAssinatura,
  },
};
</script>

<style scoped>
.formulario {
  box-shadow: rgba(194, 154, 231, 0.445);
}
.cor-violeta {
  color: #7e1dce;
}
</style>
