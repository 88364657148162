<template>
  <div class="contato text-center">
    <a :href="btnLink" class="btn btn-primary" target="_blank">{{ text }}</a>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: 'Contato',
  props: {
    text: String,
    btnLink: String,
  },
};
</script>
