<template>
  <div class="reiki-violeta">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container fundo">
      <Title text="Ativação do Reiki Alquímico da Chama Violeta" />
      <Title2 text="O que é?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="A Alquimia Quântica da Chama Violeta é um Sistema de Cura Avançada muito profunda, de Transmutação, Cura e Energização de Registros Akáshicos, Limpeza Cármica e do Ego, trazendo as energias do Amor, da Libertação, do Perdão e da Cura."
      />
      <Paragraph
        text="É uma Terapia Energética transmitida através do toque com as mãos para reabastecer instantaneamente a nossa energia vital. Sua prática pode ser empregada para promover o equilíbrio emocional e conectar a pessoa com o seu Eu Interior. Como complemento nos tratamentos médicos mais agressivos, reduzindo os sintomas e curando as causas, possibilitando o desenvolvimento da autoestima, segurança e revitalização geral, como aumento do sistema imunológico, alívio e redução de dores físicas e sentimentais como depressão, angústias, stress e ansiedade, dentre outros benefícios e situações diárias conflitantes, inclusive para tratamentos estéticos."
      />
      <Title2 text="Como funciona a Terapia?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É um Sistema de Cura Cósmica, que é ativado à distância ou presencial, com a utilização e ativação dos Símbolos da Alquimia Quântica da Chama Violeta, cristais físicos, energias Etéricas Universais, canalizadas pelo Alquimista iniciado, com o ancoramento da Hierarquia Espiritual do 7º Raio Violeta, do Mestre Saint Germain, que atuam diretamente nos planos onde se manifestam nossos corpos e nas causas das doenças."
      />
      <Paragraph
        text="A energia da cor violeta transmuta a energia negativa em positiva, capaz de ajudar o nosso corpo ou espírito. A chama violeta quando invocada é capaz de transmutar qualquer energia ou carma negativo que esteja alojado no nosso corpo físico ou espiritual, ela ajuda a elevar nossa consciência assim como aumentar a energia vital, inclusive nos desequilíbrios mentais e emocionais."
      />
      <Title2 text="Como é o Tratamento?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É realizada a consulta e atendimento, presencial ou à distância, para a troca de informações necessárias para esse fim, e a realização de um Questionário de Análise Terapêutica e envio das orientações a serem seguidas."
      />
      <Paragraph
        text="O paciente se sintoniza com as energias, em horário agendado, em ambiente tranquilo e recebe em torno de 45 minutos de tratamento intenso, com a ativação do Laboratório Alquímico e dos quatros elementos."
      />
      <br />
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'ReikiVioleta',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
