<template>
  <div class="bastao-atlantes">
    <NavBar navStyle="background-color: #3191bd" />
    <div class="container fundo">
      <Title text="Ativação da Técnica do Bastão Atlantes" />
      <Paragraph
        text="Técnica transmitida pelos Mestres Atlantes deve ser utilizada com o foco de remover fortes bloqueios nos Chakras devido
            sua elevada carga energética. Só deve ser utilizado para crianças em casos de grande necessidade."
      />
      <Paragraph
        text="Você poderá ativar esta técnica 3x vezes ao dia, ou quando sentir baixar sua vibração, os cristais etéricos atuam sempre
            com a intensidade e a necessidade de cada pessoa, por isso, faça uma reflexão sobre quais seus sentimentos e procure
            aplicar a Lei do Perdão e da Gratidão, para si e para os outros quando perceber o que as situações de sua vida vêm lhe
            mostrar, seja Mestre de Si mesmo...
            Pela Cura individual, pela cura coletiva e pela cura do Planeta Terra Urgente!"
      />
      <Title2 text="Como fazer:" titleStyle="color: #3191bd" />
      <Paragraph
        text="Junte as pontas de seus dedos, das duas mãos, formando uma ponta de cristal, colocar uma ponta no chakra
            coronário e outra no básico, ocorrendo o desbloqueio, harmonização e energização de todos os chakras, esta técnica
            necessita de iniciação, para isso peça já a sua."
      />
      <Title2 text="Conteúdo Gratuito:" titleStyle="color: #3191bd" />
      <Paragraph
        text="Solicite sua ativação individual através de nosso contato via mensagem e lhe enviaremos a ativação,
            com Amor e Gratidão!"
      />
      <Title2 text="Ativação:" titleStyle="color: #3191bd" />
      <Paragraph
        text="“Ativando Bastão de Atlantes para (DIGA SEU NOME), de (DIGA SUA CIDADE), ativando bastão de atlantes
            para o chakra coronário e ativando bastão atlantes para o chakra base.” Pela Cura individual, pela cura coletiva e pela
            cura do Planeta Terra Urgente! Gratidão!"
      />
      <br />
    </div>
    <hr />
    <Contato
      text="Agende um atendimento via Whatsapp"
      btnLink="https://api.whatsapp.com/send?phone=5554996414661"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'BastaoAtlantes',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
