<template>
  <div class="assine-ja">
    <h5>
      Assine já e receba de <span class="destaque1">BÔNUS</span> gratuitamente
      os e-books:
    </h5>
    <h4 class="destacado">
      “Ritual do Conselho Cármico” e “Equilíbrio dos Chakras”
    </h4>
    <h5>
      E receba todos os atendimentos terapêuticos pelo valor
      <span class="destaque">PROMOCIONAL</span>
    </h5>
  </div>
</template>

<script>
export default {
  name: 'AssineJa',
};
</script>

<style scoped>
.assine-ja {
  padding: 10px;
  background-color: rgb(223, 221, 247);
  color: white;
  border-radius: 40px;
  box-shadow: 6px 6px 10px rgb(198, 105, 241);
  transition: 400ms;
}

.assine-ja:hover {
  box-shadow: 8px 8px 10px rgb(198, 105, 241);
}

h5 {
  line-height: 1.8;
  margin-bottom: 6px;
  color: #552783;
  text-align: center;
  font-size: 1.1em;
}
.destacado {
  color: #d421f8;
  text-align: center;
  font-size: 1.8em;
}
.destaque1 {
  color: #dd22ff;
  text-align: center;
  font-size: 1.2em;
}
.destaque {
  color: #dd22ff;
  text-align: center;
  font-size: 1.2em;
}
</style>
