<template>
  <div class="laser-violeta">
    <NavBar navStyle="background-color: #7e1dce" />
    <div class="container fundo">
      <Title text="Aplicação do Raio Laser da Chama Violeta" />
      <Title2 text="O que é?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="O Raio Laser da Alquimia Quântica da Chama Violeta é um Sistema de Cura Cósmica, o Reiki Alquímico, que vem ampliar o acesso às energias transmutadoras e curadoras e todas as suas formas de aplicação quando manipuladas em tratamentos de cura energética, aumentando o poder de atuação dessas energias mais compatíveis com a realidade atual, da nova frequência do Planeta, em razão desta ser a própria energia de todo o Plano Divino para Era de Ouro de Aquário, do Chohan Amado Mestre Saint Germain."
      />
      <Paragraph
        text="Fonte de Energia Cósmica de alta frequência, emitida diretamente da Fonte Suprema, Luz de pura força eletrônica que, amplamente acelerada, provoca um salto quântico dos elétrons do local onde for ativado. Usado para tratamento de disfunções enraizadas, que necessita de maior intensidade de energia é uma técnica que causa resultados surpreendentes."
      />
      <Paragraph
        text="Ação concentrada de energia da Chama Violeta, potencializa e atua em curas de doenças crônicas. Quando a substância Kármica se solidifica, é como se ocorresse o endurecimento de artérias em nossos corpos espirituais. Quando ressoamos com a negatividade, gradualmente nos tornamos ela, a não ser que, façamos algo para melhorar."
      />
      <Title2 text="Como funciona a Terapia?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É uma Terapia Energética transmitida através do toque com as mãos para reabastecer instantaneamente a nossa energia vital, realinhando os chakras e limpando energia densa dos elétrons, que irão manifestar-se em doenças, voltando a girar mais rapidamente. "
      />
      <Paragraph
        text="Sua prática pode ser empregada para promover o equilíbrio emocional, conectar a pessoa com o seu Eu Interior, possibilitando o desenvolvimento da autoestima, segurança e revitalização geral, como aumento do sistema imunológico, alívio e redução de dores físicas e sentimentais como depressão, angústias, stress, ansiedade, entre outras."
      />
      <Paragraph
        text="Como complemento nos tratamentos médicos mais agressivos, reduzindo os sintomas e promovendo curas."
      />
      <Title2 text="Como é o Tratamento?" titleStyle="color: #7e1dce" />
      <Paragraph
        text="É realizada a consulta e atendimento, presencial ou à distância, para o recebimento das orientações a serem seguidas e a realização de um Questionário de Análise Terapêutica."
      />
      <Paragraph
        text="O paciente se sintoniza com as energias, em horário agendado, em ambiente tranquilo e recebe em torno de 45 minutos de tratamento intenso, com a ativação do Laboratório Alquímico e dos quatros elementos. "
      />
      <Paragraph
        text="A energia do Raio Laser é canalizada e ativada principalmente nas regiões onde já há doenças instaladas, liberando as energias mais densas, promovendo a cura com tratamentos de acordo com a necessidade de cada pessoa."
      />
      <br />
      <hr />
      <Contato
        text="Agende um atendimento via Whatsapp"
        btnLink="https://api.whatsapp.com/send?phone=5554996414661"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Paragraph from '@/components/Paragraph.vue';
import Title from '@/components/Title.vue';
import Title2 from '@/components/Title2.vue';
import Contato from '@/components/Contato.vue';

export default {
  name: 'LaserVioleta',
  components: {
    NavBar,
    Title,
    Title2,
    Paragraph,
    Contato,
  },
};
</script>
